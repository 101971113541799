@import '_base.variables.scss';

.btn {
  display: inline-flex;
  cursor: pointer;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px 20px;
  outline: 0;
  border: 0;
  border-radius: 8px;
  font-weight: 500;

  @include transition;

  &.small {
    padding: 8px 18px;
    font-size: 14px;
  }
  &.more-x {
    padding: 8px 30px;
  }
  &.x-small {
    padding: 4px 18px;
    font-size: 12px;
  }

  &.color-p {
    background-color: $color-p;
    color: $color-white;

    &:hover {
      background-color: #0947d7;
    }

    &.active {
      background-color: #5a8cf6;
    }
  }

  &.color-s {
    background-color: $color-base-gray;
    color: $color-mid-gray;

    &:hover {
      background-color: #e2e2e5;
    }
  }

  &.color-light-green {
    background-color: $color-light-green;
    color: $color-green;
    &:hover {
      background-color: $color-light-green-t;
    }
  }
  &.color-white-p {
    color: $color-p;
    background-color: #fff;
    border: 1px solid #cbd5e1;
    border-radius: 10px;

    &:hover {
      background-color: $color-p;
      color: $color-white;
    }

    &.active {
      background-color: #5a8cf6;
    }
  }

  &.color-black {
    background-color: $color-black;
    color: white;

    &:hover {
      background-color: $color-p;
      color: white;
    }
  }

  &.color-white {
    background-color: $color-white;
    color: $color-p;

    &:hover {
      background-color: $color-p;
      color: $color-white;
    }
  }

  &.color-green {
    background-color: $color-green;
    color: $color-white;
  }

  &.color-red {
    background-color: $color-red;
    color: $color-white;
  }
  &.color-r {
    background-color: $color-red;
    color: $color-white;
    &:hover {
      background-color: #d03b3b;
    }
  }
}

.btn-block {
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.btn-w-icon {
  padding: 10px 32px;
  display: inline-flex;
  align-items: center;

  .btn-icon {
    margin-right: 8px;
    width: 12px;
    height: 12px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.btn-border {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px 26px;
  outline: 0;
  border: 1px solid $color-p;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;

  @include transition;

  &.small {
    padding: 8px 18px;
  }

  &.color-p {
    border-color: $color-p;
    color: $color-p;

    &:hover {
      background-color: #0947d7;
      color: $color-white;
    }

    &.active {
      background-color: #5a8cf6;
    }
  }

  &.color-white-p {
    color: $color-p;
    background-color: #fff;
    border: 1px solid #cbd5e1;
    border-radius: 10px;

    &:hover {
      background-color: $color-p;
      color: $color-white;
    }

    &.active {
      background-color: #5a8cf6;
    }
  }

  &.color-white {
    background-color: #fff;
    border: 1px solid #cbd5e1;
    color: #64748b;
  }

  i {
    font-size: 12px;
    width: 12px;
    height: 12px;
  }
}

.btn-hidden {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0;
  visibility: hidden;
}
.btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
  &.with-rgba {
    opacity: unset;
    background: rgba(128, 128, 128, 0.5);
  }
  &.color-p:hover {
    background-color: $color-p;
    color: $color-black;
  }
  &.seven-opa {
    opacity: 0.7;
  }
  &.one-opa {
    opacity: 0.1;
  }
}

.optiyol-drop-up-btn-outer {
  position: relative;

  .optiyol-drop-up-btn {
    background: $color-p;
    color: $color-white;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 6px rgba(#0054ef, 0.16);

    i {
      margin-left: 20px;
      color: #f0f5ff;
      margin-bottom: 5px;
    }

    &.color-white {
      background-color: $color-white;
      color: #64748b;
      box-shadow: none;

      i {
        color: #64748b;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 58px;
    right: 0;
    z-index: 1;
    background: #fff;
    padding: 8px 0;
    width: 200px;
    border-radius: 6px;
    box-shadow: 0 3px 12px rgba(#57697f, 0.32);

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 8px 0 8px;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      bottom: -8px;
      right: 16px;
    }

    a {
      display: block;
      padding: 8px 24px;
    }
  }
}

.optiyol-drop-down-btn-outer {
  position: relative;

  .optiyol-drop-down-btn {
    background: $color-p;
    color: $color-white;
    padding: 10px 20px;
    border-radius: 4px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 6px rgba(#0054ef, 0.16);

    i {
      margin-left: 20px;
      color: #f0f5ff;
      margin-bottom: 5px;
    }
  }

  ul {
    position: absolute;
    top: 54px;
    right: 0;
    z-index: 1;
    background: #fff;
    padding: 8px 0;
    width: 200px;
    border-radius: 6px;
    box-shadow: 0 3px 12px rgba(#57697f, 0.32);

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 10px 8px;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      top: -8px;
      right: 16px;
    }

    a {
      display: block;
      padding: 8px 24px;
    }
  }
}

.btn-icon {
  background-color: #fbfbfd;
  color: #000;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 16px;

  &.w-text {
    width: auto;

    span {
      margin-left: 8px;
      font-weight: 500;
    }

    &.color-p {
      background: $color-p;
      color: $color-white;
    }
  }

  &.color-p {
    background: $color-p;
    color: $color-white;
  }
}

.hovered {
  &:hover {
    background-color: $color-base-gray;
    border-radius: 4px;
  }
}
