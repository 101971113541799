//=== Tags
$enable-flex: true;
$tags-color: #fff;
$primary-color: #334155;
$marked-color: lighten($primary-color, 20%);
$exist-color: #edb5a1;
$container-border: 1px solid #ccc;
$container-bg: #fff;
$container-line-height: 1.6;
$container-font-size: 14px;
$container-radius: 1px;
$container-min-height: 41px;
$container-padding: 4px;
$tags-marging: 2px 6px 2px 0;
$tags-padding: 5px 8px;
$tags-weight: 400;
$tags-radius: 8px;
$btn-outline-color: #fff;
$spacer: 15px;

*,
*:before,
*:after {
  box-sizing: border-box;
}

.tags-container {
  @if ($enable-flex) {
    display: flex;
    flex-flow: row wrap;
  }

  width: 100%;
  min-height: $container-min-height;
  padding: $container-padding;
  font-size: $container-font-size;
  line-height: $container-line-height;
  background-color: $container-bg;
  border: $container-border;
  border-radius: $container-radius;
  overflow: hidden;
  word-wrap: break-word;

  &.borderless {
    border: 0;
  }
}

input.tag-input {
  @if ($enable-flex) {
    flex: 3;
  } @else {
    display: inline-block;
    vertical-align: middle;
  }
  border: 0;
  outline: 0;
}
.tag {
  @if ($enable-flex == false) {
    display: inline-block;
    vertical-align: middle;
  }
  margin: $tags-marging;
  padding: $tags-padding;
  font-size: inherit;
  font-weight: $tags-weight;
  text-align: center;
  color: $tags-color;
  background-color: $primary-color;
  border-radius: $tags-radius;
  transition: background-color 0.3s ease;
  cursor: default;
  display: flex;
  align-items: center;
  height: 28px;

  &:first-child {
    margin-left: 0;
  }

  &--marked {
    background-color: $marked-color;
  }

  &--exists {
    background-color: $exist-color;
    animation: shake 1s linear;
  }

  &__name {
    margin-right: 3px;
  }
}
.tag__remove {
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  padding: 0 10px;
  font-size: 20px;
  font-weight: bold;
  transition: opacity 0.3s ease;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  color: #fff;
  line-height: 1;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate3d(5px, 0, 0);
  }
}
