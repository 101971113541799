.optiyol-dropdown {
  &-box {
    position: relative;
    .fas,
    .far {
      width: 25px;
    }
    ul {
      position: absolute;
      z-index: 2;
      background: #fff;
      transform: translate(35px, -35px);
      padding: 8px 0px;
      width: 200px;
      text-align: left;
      border-radius: 6px;
      box-shadow: 0 3px 12px #57697f52;
      li {
        padding: 8px 24px;
        &:hover {
          background-color: rgba(241, 245, 249, 1);
        }
      }
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 8px 10px 8px;
        transform: rotate(270deg);
        border-color: transparent transparent #fff transparent;
        position: absolute;
        top: 5px;
        left: -17px;
      }
    }
    .dropdown-from-bottom {
      bottom: 30px;
      top: auto;
      &::before {
        content: '';
        position: absolute;
        right: 16px;
        bottom: -8px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 10px solid #fff;
      }
      &::after {
        content: none;
      }
    }
  }
}
