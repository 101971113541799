.autocomplete {
  flex: 1;
  position: relative;
  background-color: white;
  font-size: 10px;
  input {
    font-size: 13px;
  }
  .found {
    background-color: rgba(247, 181, 0, 0.2);
  }
  &.open {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
  }
  ul {
    position: absolute;
    background-color: #fff;
    border: 1px solid #e2e8f0;
    width: 100%;
    li {
      padding-bottom: 5px;
      padding: 10px 15px;
      cursor: pointer;
      color: #131340;
      font-weight: 300;
      font-size: 13px;
      border-bottom: 1px solid #f0f0f3;
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background-color: rgba(214, 212, 202, 0.2);
      }
      &.active {
        background-color: rgba(214, 212, 202, 0.2);
      }
      &:last-child {
        border-bottom: none;
      }
      .fas {
        width: 24px;
      }
    }
  }
}
