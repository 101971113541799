.orders-list-container {
  width: 100%;
  padding: 16px;
  .inner {
    width: 100%;
    border-radius: 10px;
    border: solid 1px #ececf2;
    .item {
      height: 81px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      position: relative;
      &.extra {
        height: 50px;
      }
      &.with-divider {
        &:not(:first-child)::after {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 1px;
          background-color: #ececf2;
        }

        &:not(:last-child) ::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 1px;
          background-color: #ececf2;
        }
      }

      .fas {
        background-color: transparent;
        font-size: 20px;
      }
      span {
        color: #1c1d21;
      }
      .text-container {
        width: 202px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: start;
      }
      p {
        color: #8181a5;
      }
      .count {
        color: #8181a5;
        margin-left: auto;
        width: 60px;
        text-align: right;
      }
      &.active {
        .fas {
          color: #185cf7;
        }
        span {
          color: #185cf7;
        }
        .count {
          font-weight: bold;
          color: #1c1d21;
        }
        &.red {
          .fas {
            color: #e02020;
          }
          span {
            color: #e02020;
          }
        }
      }

      &.active-bold {
        &.first {
          border-radius: 10px 10px 0px 0px;
        }
        &.mid {
          border-radius: 0px;
        }
        &.end {
          border-radius: 0px 0px 10px 10px;
        }

        background-color: #334155;
        .fas {
          color: white;
        }
        span {
          color: white;
        }
        p {
          color: white;
        }
        .count {
          font-weight: bold;
          color: white;
        }
      }
    }
  }
}
.order-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f5f5fa;
  .content {
    margin: 20px;
    background-color: #fff !important;
    border-radius: 15px;
  }
  .loadFileDiv {
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-direction: row;
    align-items: center;
    $widthExtra: 40px;
    margin: 20px;
    margin-top: 0px;
    width: calc(100% - #{$widthExtra});
    padding: 16px;
    border: solid 1px #ececf2;
    border-radius: 12px;
  }
  .more-btn {
    border: solid 1px #ececf2;
    border-radius: 5px;
    margin-left: 20px;
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }
}

.excel-icon {
  padding: 6px;
  font-size: 26px;
  border-radius: 5px;
  color: #1d6f42;
  background-color: rgba(29, 110, 66, 0.15);
  margin: 4px;
}

.order-add-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0px;
  height: 73px;
  width: 100%;
  background-color: #283547;
  padding: 20px;

  .add-button {
    padding: 10px 20px;
    background-color: #185cf7;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 11px;
    cursor: pointer;
    &:hover {
      background-color: #1247c2;
    }

    &.white {
      color: #8181a5;
      background-color: white;
      &:hover {
        background-color: #ddd;
      }
    }

    &.disable {
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        background-color: #185cf7;
      }
    }
    &.hidden {
      visibility: hidden;
    }
  }
}
.column-select {
  height: 38px;
  opacity: 1;
}

.css-oof02h-option {
  background-color: #eee !important ;
}

.bar-column {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: $color-light-gray;
  .bar-colored {
    height: 5px;
    border-radius: 5px;
  }
}
.table-type-btn-container {
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 12px;
  width: 100%;
  height: 60px;
  padding: 20px 40px 0px 40px;
  .table-type-btn {
    margin-right: 10px;
    padding: 10px 20px;
    &.active {
      border: solid 1px #ececf2;
      border-radius: 6px;
    }
  }
}
