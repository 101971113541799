.optiyol-toast {
  background: #f0f5ff;
  padding: 7px 20px;
  color: #021e64;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10000;
  border-radius: 4px;
  border: 1px solid;
  border-width: 1px 1px 1px 12px;
  border-color: $color-p;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include transition;

  .optiyol-toast-icon {
    font-size: 28px;
    color: $color-p;
    display: inline-block;
    margin-right: 14px;
  }
  .optiyol-toast-close {
    font-size: 14px;
    padding: 10px;
    color: #82a9f8;
  }

  &.green {
    border-color: #10b981;
    background-color: #edfdf5;
    color: #054f3b;

    .optiyol-toast-icon {
      color: #10b981;
    }
    .optiyol-toast-close {
      color: #6ee7b7;
    }
  }

  &.red {
    border-color: #f04444;
    background-color: #fef2f2;
    color: #7f1d1d;

    .optiyol-toast-icon {
      color: #f04444;
    }
    .optiyol-toast-close {
      color: #fca6a5;
    }
  }

  &.orange {
    border-color: #f59e26;
    background-color: #fffbeb;
    color: #78350f;

    .optiyol-toast-icon {
      color: #f59e26;
    }
    .optiyol-toast-close {
      color: #fcd34d;
    }
  }
}
