.date-range {
  position: relative;
  .icon {
    position: relative;

    cursor: pointer;
    &.filtered {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        top: -2px;
        right: -2px;
        background-color: $color-p;
      }
    }
  }
  .date-range-picker-box {
    position: absolute;
    z-index: 2;
    border: 1px solid #f3f3f6;
    border-radius: 5px;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
    padding: 12px;
    background-color: #fff;
    &.simple {
      box-shadow: 4px 4px 5px 3px rgba(0, 0, 0, 0.3);
    }
    .header {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    .rdrCalendarWrapper {
      border: 1px solid $color-light-gray;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .rdrDateDisplayWrapper {
      border-radius: 10px 10px 0 0;
    }
    .btn-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        padding: 11px 45px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 300;
        &-clear {
          background-color: $color-light-gray;
          color: $color-mid-gray;
        }
        &-filter {
          background-color: $color-p;
          color: #fff;
        }
      }
    }
  }
}

.date-picker {
  position: relative;
  .shadow {
    box-shadow: 3px 3px 8px #8181a5;
    border-radius: 8px;
  }
  .rdrCalendarWrapper {
    border-radius: 8px;
    .rdrDateDisplayWrapper {
      display: none;
    }
    .rdrMonthAndYearWrapper {
      padding: 0;
    }
  }
}

.react-time-picker {
  &__wrapper {
    border: 0 !important;
  }
  &__inputGroup {
    flex: 1 !important;
    &__input {
      box-sizing: content-box !important;
    }
  }
  &__clock {
    display: none;
  }
}

.react-clock {
  background-color: #fff;
  border-radius: 50%;
}
