$line-height: 1.5;

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 14px;
  height: 100%;

  &.html-no-scroll {
    overflow-y: hidden;
    overscroll-behavior: none;
  }
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: $color-black;
  line-height: $line-height;
  font-weight: 400;
  word-wrap: break-word;
  -webkit-font-kerning: normal;
  font-kerning: normal;

  &.body-no-scroll {
    overflow: hidden;
    overscroll-behavior: none;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'Roboto', sans-serif;
}

h1,
.h1 {
  font-size: 26px;
}

h2,
.h2 {
  font-size: 22px;
}

h3,
.h3 {
  font-size: 20px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 16px;
}

h6,
.h6 {
  font-size: 14px;
}

p:not(:last-child) {
  margin-bottom: 1.5rem;
}

i {
  font-style: italic;
}

b {
  font-weight: bold;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.bold,
strong {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-not-uppercase {
  text-transform: initial !important;
}

.text-underline {
  text-decoration: underline !important;
}

ul.unordered {
  list-style: disc;
  list-style-position: inside;

  li {
    margin-bottom: 10px;
  }
}
.text-8 {
  font-size: 8px !important;
}
.text-10 {
  font-size: 10px !important;
}
.text-12 {
  font-size: 12px !important;
}

.text-13 {
  font-size: 13px !important;
}

.text-14 {
  font-size: 14px !important;
}
.text-16 {
  font-size: 16px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-24 {
  font-size: 24px !important;
}
.text-32 {
  font-size: 32px !important;
}
.lh-1 {
  line-height: 1;
}

.lh-1-3 {
  line-height: 1.3;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.op-50 {
  opacity: 0.5;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
