.tooltip-container {
  .tooltip-content {
    opacity: 0;
    padding: 4px 6px;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    transition-property: opacity;
    transition-duration: 0s;
    overflow: scroll;
    top: 10px;
    margin-left: -20px;
    z-index: 1000;
    font-size: 12px;
    max-width: 260px;
  }
  &:hover .tooltip-content {
    transition-delay: 500ms;
    opacity: 1;
  }
}
