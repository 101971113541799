.master-data {
  &-list-item {
    padding: 13px 18px;
    padding-bottom: 5px;
    border-bottom: 1px solid;
    border-color: $color-light-gray;
    .row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    .badge {
      width: 36px;
      height: 36px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #b71c1c;
      border-radius: 10px;
      color: white;
    }
    .text {
      max-width: 180px;
    }
    .icons {
      display: flex;
      align-self: center;
      margin-top: 4px;
      margin-left: auto;
      .fas {
        height: unset;
        width: unset;
        background-color: transparent;
        color: $color-dark-gray;
      }
    }
  }
}

.master-data-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 40px;
  min-height: 76px;
  border-bottom: 1px solid #f0f0f3;
  .add-button {
    padding: 10px 20px;
    background-color: $color-p;
    color: #fff;
    border-radius: 10px;
    .fas {
      margin-right: 10px;
    }
  }
}

.master-data-infobar {
  width: 387px;
  height: 100%;
  color: #1c1d21;
  .infobar-header {
    height: 84px;
    padding: 23px;
    color: inherit;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .summary-info {
    &.height {
      height: calc(100vh - 136px);
    }
    overflow: scroll;
    .section {
      color: #1c1d21;
      margin: 10px 0;
      border-bottom: 1px solid $color-light-gray;
      &-header {
        font-size: 14px;
        font-weight: 500;
      }
      &-item {
        font-size: 14px;
        margin: 7px 0;
        .fas {
          font-size: 18px;
          color: $color-mid-gray;
          width: 30px;
        }
      }
      .box-item {
        padding: 18px 17px 18px 25px;
        display: flex;
        background-color: rgba(245, 245, 250, 0.4);
        color: #1c1d21;
        margin: 7px 0;
        border-radius: 8px;
        &.small {
          padding: 8px 17px 8px 25px;
        }
        &.capacity {
          display: block;
          .bar {
            width: 100%;
            height: 5px;
            border-radius: 4px;
          }
        }
        .fas {
          width: 20px;
          margin-right: 10px;
        }
        .success {
          background-color: #6dd400;
          color: #fff;
          font-size: 13px;
          text-align: center;
          line-height: 19px;
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
        .warning {
          font-size: 20px;
          color: #f4be5e;
        }
        .error {
          font-size: 20px;
          color: $color-red;
        }
        .label {
          flex: 1;
        }
      }
      .subheader {
        margin-bottom: 5px;
        font-size: 13px;
        color: $color-mid-gray;
      }
      .restrict-tag {
        padding: 10px 20px;
        background-color: #f5f5fa;
        color: $color-mid-gray;
        border-radius: 8px;
        display: inline-block;
        margin: 0 10px 10px 0;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #f0f0f3;
  }
  .info {
    padding: 23px;
    padding-right: 10px;
    color: inherit;
  }
}
.steps {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 0 20px;
    &.color-s {
      .step-text {
        color: $color-s;
      }
      &::after {
        background-color: $color-s;
      }
    }
    &-text {
      margin-top: 6px;
      color: #fff;
      font-size: 12px;
    }
    &::after {
      content: '';
      position: absolute;
      top: 10px;
      left: 0;
      width: 50%;
      z-index: 0;
      height: 2px;
      background-color: #fff;
    }
    .circle {
      z-index: 1;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #939aa3;
      position: relative;
      @include transition;
      .inner {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
    &-active {
      .circle {
        background-color: $color-p;
      }
    }
    &-completed {
      .circle {
        background-color: #26f718;
      }
    }
    &.single {
      &::after {
        height: 0;
      }
    }
    &.first {
      &::after {
        right: 0 !important;
        left: auto;
      }
    }
    &.mid {
      &::after {
        width: 100%;
      }
    }
  }
}
.add-wizard {
  flex: 1;
  display: flex;
  flex-direction: column;
  .wizard-header {
    background-color: #283547;
    width: 100%;
    min-height: 84px;
    .steps {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .step {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        padding: 0 20px;
        &-text {
          margin-top: 6px;
          color: #fff;
        }
        &::after {
          content: '';
          position: absolute;
          top: 10px;
          left: 0;
          width: 50%;
          z-index: 0;
          height: 2px;
          background-color: #fff;
        }
        .circle {
          z-index: 1;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #939aa3;
          position: relative;
          @include transition;
          .inner {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
        &-active {
          .circle {
            background-color: $color-p;
          }
        }
        &-completed {
          .circle {
            background-color: #26f718;
          }
        }
        &.single {
          &::after {
            height: 0;
          }
        }
        &.first {
          &::after {
            right: 0 !important;
            left: auto;
          }
        }
        &.mid {
          &::after {
            width: 100%;
          }
        }
      }
    }
  }
  .content {
    flex: 1;
    background-color: $color-light-gray;
    position: relative;

    form {
      flex: 1;
    }
  }
  .map-content {
    width: 100%;
    height: calc(100vh - 157px);
    display: flex;
    background-color: $color-light-gray;
  }
  .form-content {
    height: calc(100vh - 157px);
    background-color: #eee;
    padding: 35px 45px;
    overflow-y: scroll;
    .form-like {
      border-radius: 12px;
      background-color: #fff;
      padding: 35px 20px 35px 45px;
      min-height: 100%;
    }
    form {
      border-radius: 12px;
      background-color: #fff;
      padding: 35px 20px 35px 45px;
      min-height: 100%;
    }
    &-header {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
    .divider {
      height: 1px;
      background-color: $color-light-gray;
      margin-right: 24px;
    }
    .form-group {
      margin: 10px 0;
      &-header {
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        margin-top: 4px;
      }
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4px;
        .column {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          padding-right: 24px;
          margin: 8px 0;
          label {
            color: $color-mid-gray;
            font-weight: 500;
          }
          input::placeholder {
            color: $color-mid-gray;
            font-weight: 300;
          }
          .react-select__placeholder {
            color: $color-mid-gray;
          }
          .error {
            color: $color-red;
            position: absolute;
            bottom: -19px;
            left: 0;
            font-size: 12px;
          }
          .form-icon {
            position: absolute;
            bottom: 10px;
            right: 30px;
          }
          .optiyol-modal-body {
            .input {
              .fas {
                position: initial;
              }
            }
          }
        }
      }
      .react-select {
        &__control {
          border-width: 0;
          border-bottom-width: 1px;
          box-shadow: unset;
          border-radius: 0;
          &--is-focused {
            border-width: 0;
            border-bottom-width: 1px;
          }
        }
      }
      .rc-time-picker-input {
        border-width: 0;
        border-bottom-width: 1px;
        border-radius: 0;
      }
    }
  }
  .map-wizard-footer {
    position: static !important;
  }
  .wizard-footer {
    background-color: #283547;
    width: 100%;
    height: 73px;
    padding: 21px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    .btn {
      position: relative;
      cursor: pointer;
      &-prev {
        background-color: #fff;
        color: $color-mid-gray;
        font-size: 14px;
      }
      &-next {
        background-color: $color-p;
        color: #fff;
        font-size: 14px;
      }
      .fas {
        font-size: 17px;
        position: absolute;
        left: 10px;
        margin: 0;
        top: 50%;
        transform: translate(0, -55%);
        &.right {
          right: 10px;
          left: unset;
        }
      }
    }
  }
}

.upload-method {
  display: flex;
  margin-top: 20px;
  .modal-select {
    width: 32%;
    margin-right: 20px;
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

.dropzone {
  border-radius: 8px;
  border: 1px dashed $color-mid-gray;
  padding: 15px;
  cursor: pointer;
  &:hover {
    border-color: $color-dark-gray;
    border-width: 2px;
  }
}

.add-plan-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid $color-light-gray;
  .add-button {
    font-size: 13px;
    .fas {
      margin-right: 10px;
      font-size: 16px;
    }
  }
}

.polygon-detail-layout {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  display: flex;
  flex: 1;
  height: 100vh;
  width: 100%;
  .details {
    width: 350px;
    .section {
      padding: 5px 18px;
      border: 0;
    }
    .detail-header {
      background-color: #283547;
      color: #fff;
      padding: 20px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 71.8px;
      .fas {
        cursor: pointer;
        background-color: #f2f2f6;
        color: $color-mid-gray;
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
}

.avoid-area-detail-layout {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  width: 100%;
  .details {
    width: 100%;
    .section {
      padding: 5px 18px;
      border: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .info-item {
        width: 32%;
      }
    }
    .detail-header {
      background-color: #283547;
      color: #fff;
      padding: 20px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 71.8px;
      .fas {
        cursor: pointer;
        background-color: #f2f2f6;
        color: $color-mid-gray;
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
}
.prn-parse-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 60px);
  padding: 6px;
  background-color: #f5f5fa;
  .prn-parse-data-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
  }
  .selected-column-dd-card-container {
    width: 100%;
    .selected-column-dd-card-container-input-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .single-input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 48%;
        .single-input-label {
          font-size: 12px;
          color: #8181a5;
        }
        .single-input-input-extra {
          padding: 6px 6px 6px 3px;
        }
      }
    }
    .selected-column-dd-card-container-separator {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .single-input-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .single-input-label {
          font-size: 12px;
          color: #8181a5;
        }
        .single-input-input-extra {
          width: 30px;
          padding: 3px;
        }
      }
    }
  }
}

.prn-data-preview-container {
  width: calc(100vw - 360px);
}
