@mixin generate($prefix, $property) {
  $sizes: (0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 32, 46, 68, 96);

  $spacing: 1px;

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);

    .#{$prefix}-#{$size} {
      #{$property}: $spacing * $size !important;
    }
  }
}

$rules: (
  (m, margin),
  (ml, margin-left),
  (mt, margin-top),
  (mb, margin-bottom),
  (mr, margin-right),
  (p, padding),
  (pl, padding-left),
  (pt, padding-top),
  (pb, padding-bottom),
  (pr, padding-right)
);

@each $item in $rules {
  @include generate(nth($item, 1), nth($item, 2));
}

.m-auto {
  margin: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.m-0 {
  margin: 0 !important;
}
.p-0 {
  padding: 0 !important;
}

.w-100 {
  width: 100%;
}
.w-auto {
  width: auto !important;
}
.h-100 {
  height: 100vh;
}

.hr {
  margin: 2em 0;
  height: 1px;
  width: 100%;
  background: $color-s;
}

.optiyol-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.bordered {
  border: 1px solid rgba($color-mid-gray, 0.5) !important;
  &.color-p {
    border-color: $color-p !important;
  }
}

.border-t {
  border-top: 1px solid rgba($color-mid-gray, 0.5) !important;
}

.border-b {
  border-bottom: 1px solid rgba($color-mid-gray, 0.5) !important;
}

.border-l {
  border-left: 1px solid rgba($color-mid-gray, 0.5) !important;
}

.border-r {
  border-right: 1px solid rgba($color-mid-gray, 0.5) !important;
}
.borderless {
  border: 0 !important;
}

.br-2 {
  border-radius: 2px;
}
.br-4 {
  border-radius: 4px;
}
.br-8 {
  border-radius: 8px;
}
.br-12 {
  border-radius: 12px;
}
.br-24 {
  border-radius: 24px;
}
.br-full {
  border-radius: 9999px;
}
