/**/

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.d-flex.row {
  flex-flow: row wrap;
}

.d-flex.col {
  flex-flow: column wrap;
}

.d-flex.column {
  flex-flow: column wrap;
}

.d-flex.align-center {
  align-items: center;
}

.d-flex.align-start {
  align-items: flex-start;
}

.d-flex.align-end {
  align-items: flex-end;
}

.d-flex.justify-center {
  justify-content: center;
}

.d-flex.justify-space {
  justify-content: space-between;
}

.d-flex.justify-evenly {
  justify-content: space-evenly;
}

.d-flex.justify-around {
  justify-content: space-around;
}

.d-flex.justify-end {
  justify-content: flex-end;
}

.d-flex.justify-start {
  justify-content: flex-start;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow-vert {
  flex: 1 0 auto;
}
.align-self-center {
  align-self: center;
}

.#{$site-var}-col-1 {
  width: 100%;
}
.#{$site-var}-col-2 {
  width: 46%;
  margin: 0 2%;

  @include breakpoint(tablet) {
    width: 100%;
    margin: 0;
  }
}

.#{$site-var}-col-3 {
  width: calc(100% / 3);

  @include breakpoint(tablet) {
    width: 100%;
  }
}

.#{$site-var}-col-1-3 {
  width: calc(100% / 3);

  @include breakpoint(mobile) {
    width: 100%;
  }
}

.#{$site-var}-col-2-3 {
  width: calc(100% / 3 * 2);

  @include breakpoint(mobile) {
    width: 100%;
  }
}

.#{$site-var}-col-1-4 {
  width: calc(100% / 12 * 3);

  @include breakpoint(mobile) {
    width: 100%;
  }
}

.#{$site-var}-col-3-4 {
  width: calc(100% / 12 * 9);

  @include breakpoint(mobile) {
    width: 100%;
  }
}

.flex-1 {
  flex: 1;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

.w-30 {
  width: 30% !important;
}

.min-height-100 {
  min-height: 100vh;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}
.flex-none {
  flex: none;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-baseline {
  align-self: baseline;
}
.self-stretch {
  align-self: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
