.captcha-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 300px;
  background-color: #f9fcfc;
  &.valid {
    border-color: green;
  }

  &.invalid {
    border-color: red;
  }
  .math-problem {
    font-size: 24px;
    margin-bottom: 10px;
    user-select: none;
    .math-number {
      color: #007bff;
      font-weight: bold;
      font-family: 'Courier New', Courier, monospace;
    }

    .math-operator {
      color: #ff6347;
      font-weight: bold;
      font-family: 'Arial', sans-serif;
    }
  }

  .captcha-input {
    padding: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    &.valid {
      border-color: green;
    }

    &.invalid {
      border-color: red;
    }
  }
}
