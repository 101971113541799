.planning-infobubble {
  color: #1c1d21;
  font-size: 13px;
  padding-top: 15px;
  z-index: 6;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    padding: 0px 15px;
    .text {
      margin-left: 8px;
      font-size: 16px;
      font-weight: bold;
      color: #1c1d21;
    }
    .icon {
      width: 23px;
      height: 22px;
    }
  }
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-top: 10px;
    font-size: 10px;
    width: 100%;
    div:first-child {
      border: 1px #ececf2 solid;
      border-radius: 10px 0px 0px 10px;
    }
    div:last-child {
      border: 1px #ececf2 solid;
      border-radius: 0px 10px 10px 0px;
    }
    div {
      padding: 5px 10px;
      border: solid #ececf2;
      vertical-align: middle;
      border-width: 1px 0px 1px 0px;
      cursor: pointer;
      color: '#252631';
      &:hover {
        background-color: #e2e2e2;
      }
      &.active {
        background-color: #334155;
        color: white;
      }
    }
  }
  .info-division {
    height: 360px;
    width: 400px;
    padding: 0 10px;
    overflow: scroll;
    overflow-x: hidden;
    .expandable-item {
      border-bottom: 1px solid #ececf2;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 16px;
        cursor: pointer;
      }
      .expand-content {
        display: flex;
        flex-wrap: wrap;
        padding: 0px 16px 10px 16px;
        width: 380px;
      }
      .route-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ececf2;
        border-radius: 6px;
        padding: 0px 8px;
        width: 100%;
      }
    }
    .column {
      &.two {
        width: 50%;
        overflow-wrap: anywhere;
        align-items: flex-start;
      }
      &.one {
        width: 100%;
        overflow-wrap: anywhere;
        align-items: flex-start;
      }
      padding-top: 4px;
      display: flex;
      align-items: center;
      color: #1c1d21;
      i {
        margin-top: 4px;
        font-size: 20px;
        margin-right: 8px;
        color: #8181a5;
        &.green {
          color: #6dd400;
        }
        &.orange {
          color: #fa6400;
        }
        &.red {
          color: #e02020;
        }
      }
    }
  }
  .bubble-footer {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid $color-light-gray;
    .text {
      font-weight: bold;
      color: #334155;
      .green {
        color: #6dd400;
      }
      .red {
        color: #e02020;
      }
    }
    .icon {
      background-color: #f2f2f6;
      cursor: pointer;
      width: 32px;
      height: 32px;
      margin-right: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      &.blue {
        background-color: #185cf7;
        i {
          color: white;
          font-size: 16px;
        }
      }
      &.red {
        background-color: $color-red;
        i {
          color: white;
          font-size: 16px;
        }
      }
      i {
        pointer-events: none;
        color: #8181a5;
        font-size: 16px;
      }
    }
    .tracking-page-redirect {
      font-size: 16px;
      display: flex;
      align-items: center;
    }
  }
  .date-time {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;

    div {
      border: 1px #ececf2 solid;
      width: 150px;
      font-size: 12px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      i {
        color: #8181a5;
        font-size: 16px;
        margin-right: 4px;
      }
    }
    div:first-child {
      border-radius: 5px 0px 0px 5px;
    }
    div:last-child {
      border-radius: 0px 5px 5px 0px;
    }
  }
}

.infobubble-fix-location {
  background-color: $color-mid-gray;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  margin-top: 10px;
  cursor: pointer;
  .fas {
    margin-right: 5px;
  }
}

.paymentTypeDivision {
  display: flex;
  padding: 0px 12px;
  border-radius: 6px;
  border: 1px solid #ececf2;
}

.remove-order-from-list-btn {
  background-color: #f2f2f6;
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  &.red {
    background-color: $color-red;
    i {
      color: white;
      font-size: 16px;
    }
  }
}
