.filter {
  position: relative;
  .icon {
    cursor: pointer;
    &.filtered {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        top: -2px;
        right: 2px;
        background-color: $color-p;
      }
    }
  }
  .filter-box {
    position: absolute;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
    right: 0;
    z-index: 15;
    border: 1px solid #f3f3f6;
    border-radius: 5px;
    padding: 20px 25px;
    background-color: #fff;
    width: 420px;
    max-height: calc(100vh - 170px);
    overflow: hidden scroll;
    .filter-header {
      font-size: 18px;
      color: #1c1d21;
      display: flex;
      .close {
        cursor: pointer;
        margin-left: auto;
        .icon {
          color: black;
          background-color: transparent;
        }
      }
    }
    .status-box {
      margin-top: 20px;
      margin-bottom: 10xp;
      .label {
        color: $color-mid-gray;
        font-size: 14px;
        font-weight: 300;
      }
      .statuses {
        display: flex;
        margin-top: 5px;
        cursor: pointer;
        .status {
          padding: 5px 14px;
          border-radius: 8px;
          border: 1px solid;
          margin-right: 10px;
          @include transition;
        }
      }
    }
    .btn-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .btn {
        padding: 11px 45px;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 300;
        &-clear {
          background-color: $color-light-gray;
          color: $color-mid-gray;
        }
        &-filter {
          background-color: $color-p;
          color: #fff;
        }
      }
    }
  }
}

.inside-filter-box {
  background-color: #fff;
  .filter-header {
    font-size: 18px;
    color: #1c1d21;
    display: flex;
    .close {
      cursor: pointer;
      margin-left: auto;
      .icon {
        color: black;
        background-color: transparent;
      }
    }
  }
  .status-box {
    margin: 10px 0;
    .label {
      color: $color-mid-gray;
      font-size: 14px;
    }
    .statuses {
      display: flex;
      margin-top: 5px;
      cursor: pointer;
      .status {
        padding: 7px 20px;
        border-radius: 5px;
        border: 1px solid;
        margin-right: 15px;
        @include transition;
      }
    }
  }
  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    .btn {
      padding: 11px 45px;
      border-radius: 10px;
      cursor: pointer;
      font-weight: 300;
      &-clear {
        background-color: $color-light-gray;
        color: $color-mid-gray;
      }
      &-filter {
        background-color: $color-p;
        color: #fff;
      }
    }
  }
}
