.stepper {
  display: flex;
  flex-direction: row;
  .stepper-item {
    width: 150px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.blue-active {
      border-color: #185cf7;
      color: #185cf7;
    }
    &.green {
      border-color: #8af1b9;
      color: #8af1b9;
    }
    &.gray {
      border-color: #939aa3;
      color: white;
    }
    .separator {
      position: relative;
      left: 90px;
      top: -11px;
      width: 140px;
      height: 2px;
      background-color: white;
    }
    .stepper_circle {
      width: 20px;
      height: 20px;
      background-color: #fff;
      border: 6px solid;
      border-color: inherit;
      border-radius: 50%;
    }
  }
}
