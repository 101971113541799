.react-tiny-popover-container {
  z-index: 999999;
  .popover-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: solid;
    border-width: 1px;
    border-radius: 10px;
    border-color: white;
    padding: 10px;
    opacity: 1;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);

    .disabled {
      pointer-events: none;
      opacity: 0.6;
    }
    h1 {
      font-size: 12px;
    }
    h3 {
      font-size: 10px;
    }
    a {
      font-size: 12px;
      margin: 5px;

      .red {
        color: red;
      }
    }
    span {
      font-size: 12px;
      margin-left: 5px;
    }
    .container-item {
      cursor: pointer;
      &:hover {
        background-color: $color-light-gray;
        border-radius: 5px;
      }
      a {
        display: block;
        width: 100%;
        padding: 5px 12px;
        margin: 0;
        font-size: 12px;
        .red {
          color: red;
        }
      }
    }
  }
  .text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .dot {
    height: 11px;
    width: 11px;
    margin-top: 2px;
    border-radius: 50%;
    display: inline-block;
  }

  .bar-popover {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background-color: $color-light-gray;
    .bar-colored {
      height: 5px;
      border-radius: 5px;
    }
  }

  .utilization-bar {
    margin: 5px 6px;
    padding: 8px;
    border-radius: 6px;
    background-color: rgba(245, 245, 250, 0.4);
    .bar-popover {
      width: 100%;
      height: 5px;
      border-radius: 5px;
      background-color: $color-light-gray;
      .bar-colored {
        height: 5px;
        border-radius: 5px;
      }
    }
  }
}

/* .popover-arrow {
  top: 17px !important;
} */
