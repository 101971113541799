$site-var: optiyol;

$color-black: #0f182a !default;
$color-white: #fff !default;
$color-p: #195ef6 !default;
$color-p-mid: #2f69e4 !default;
$color-p-half: #195ef6aa !default;
$color-s: #021e64 !default;
$color-red: #f04444 !default;
$color-green: #0d845c !default;
$color-orange: #f59e26 !default;
$color-light-green: rgba(109, 212, 0, 0.1) !default;
$color-light-green-t: rgba(109, 212, 0, 0.2) !default;
$color-base-gray: #fbfbfd !default;
$color-light-gray: #eeeeee !default;
$color-mid-gray: #8181a5 !default;
$color-dark-gray: #595959 !default;
$color-dark-black: #334155 !default;

$font-body: 'Roboto', sans-serif;
$font-medium: 500;
$font-bold: 700;

@mixin transition($properties: all, $duration: 0.3s, $easing: ease) {
  @if length($properties) > 1 {
    transition-property: $properties;
    transition-duration: $duration;
    transition-timing-function: $easing;
  } @else {
    transition: $properties $duration $easing;
  }
}
