@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles'; // if using DnD
body {
  --contexify-menu-bgColor: #eeeeee;
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-item-color: #0f182a;
  --contexify-activeItem-color: #0f182a;
  --contexify-activeItem-bgColor: #d3e1f6;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}
.rbc-calendar {
  font-family: 'Roboto', sans-serif;

  .rbc-toolbar {
    &-label {
      font-weight: 700;
      font-size: 16px;
    }
  }
  .rbc-btn-group {
    button[type='button'] {
      padding: 7px 20px;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        background-color: $color-white;
      }
      &:focus {
        background-color: $color-white;
        color: $color-black;
      }
      &:active,
      &.rbc-active {
        background-color: $color-p;
        color: $color-white;
        box-shadow: unset;
      }
    }
  }
  .rbc-time-view {
    .rbc-time-header {
      .rbc-header {
        border-bottom: none;
        padding: 10px 0;
      }
      .rbc-allday-cell {
        display: none;
      }
    }
    .rbc-time-content {
      .rbc-time-gutter {
        width: 100px;
        text-align: center;
      }
    }
  }
  .rbc-event {
    border: 1px solid $color-white;
    background-color: #2485f7;
  }
}
