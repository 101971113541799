// -------------------------------------------------------
// Transition styles
// -------------------------------------------------------

@mixin transition($properties: all, $duration: 0.3s, $easing: ease-out) {
  @if length($properties) > 1 {
    transition-property: $properties;
    transition-duration: $duration;
    transition-timing-function: $easing;
  } @else {
    transition: $properties $duration $easing;
  }
}

.optiyol-modal-transition-enter {
  opacity: 0;
  transform: scale(0.7);
}
.optiyol-modal-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 400ms;
}
.optiyol-modal-transition-exit {
  opacity: 1;
}
.optiyol-modal-transition-exit-active {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 300ms, transform 400ms;
}
.optiyol-modal-transition-exit-done {
  opacity: 0;
  visibility: hidden;
}

.optiyol-planning-table-move-enter {
  opacity: 0;
  transform: translate(-90px, 0);
}
.optiyol-planning-table-move-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: transform 600ms ease-in, opacity 400ms ease;
}
.optiyol-planning-table-move-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.optiyol-planning-table-move-exit-active {
  opacity: 0;
  transform: translate(90px, 0);
  transition: transform 600ms ease-in, opacity 400ms ease;
}

.optiyol-footer-buttons-transition-enter {
  opacity: 0;
}
.optiyol-footer-buttons-transition-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.optiyol-footer-buttons-transition-exit {
  opacity: 1;
}
.optiyol-footer-buttons-transition-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.transition-open {
  visibility: visible;
  opacity: 1;
  transition: all 300ms ease;
}
.transition-closed {
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease;
}

.optiyol-list-transition-enter {
  transform: scale(0.7);
}
.optiyol-list-transition-enter-active {
  transform: scale(1);
  transition: all 5000ms ease;
}

.optiyol-list-transition-exit {
  transform: scale(1);
  visibility: hidden;
}
.optiyol-list-transition-exit-active {
  transform: translateX(0px);
  transition: all 5000ms ease;
}

.optiyol-filter-transition-enter {
  opacity: 0;
  transform: scale(0.7);
}
.optiyol-filter-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 200ms, transform 200ms;
}
.optiyol-filter-transition-exit {
  opacity: 1;
}
.optiyol-filter-transition-exit-active {
  opacity: 0;
  transform: scale(0.7);
  transition: opacity 200ms, transform 200ms;
}

.optiyol-listview-transition-enter {
  opacity: 0;
  transform: translateX(300px);
}
.optiyol-listview-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 500ms;
}
.optiyol-listview-transition-exit {
  opacity: 1;
  transform: translateX(0);
}
.optiyol-listview-transition-exit-active {
  opacity: 0;
  transform: translateX(300px);
  transition: opacity 300ms, transform 500ms;
}

.optiyol-informationview-enter {
  opacity: 0;
  transform: translateX(100vw);
}
.optiyol-informationview-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 400ms, transform 400ms;
}
.optiyol-informationview-exit {
  opacity: 1;
}
.optiyol-informationview-exit-active {
  opacity: 0;
  transform: translateX(calc(100vw + 300px));
  transition: opacity 400ms, transform 400ms;
}
