.sndrll-header {
    background: $color-white;
    border-bottom: 1px solid rgba($color-mid-gray, .25);
    padding: 10px 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    z-index: 15;

    &.sndrll-checkout-header {
        padding: 16px 0
    }




    @include breakpoint(web) {
        padding: 4px 20px;


        &.sndrll-checkout-header {
            padding: 16px 0;

            .sndrll-logo{
                position: relative;
                left:auto;
                transform: none;
            }
        }
    }
}

.sndrll-logo {

    @include breakpoint(web) {
        margin: 0 auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    @include breakpoint(tablet) {

        img {
            width: 100px;
        }
    }
}

.sndrll-header-right {
    width: calc(100% - 160px);

    @include breakpoint(web) {
        width: auto;
    }
}

.sndrll-hamb-bttn {
    display: none;
    font-size: 28px;
    margin-top: 5px;
    padding: 8px 8px 8px 0;

    @include breakpoint(web) {
        display: block;
    }
}

.sndrll-main-nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
}

.sndrll-nav {

    li {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 6px;
        }
    }

    a {
        display: block;
        padding: 15px 10px;
        position: relative;
        letter-spacing: 0.04em;

        &:hover {
            color: $color-dark-gray;
        }
    }
}

.sndrll-header-search {
    display: inline-block;
    line-height: 1;
    padding: 4px;
    margin-right: .5rem;
}

.sndrll-bag {
    margin-right: 1rem;
    position: relative;
    line-height: 1;

    @include breakpoint(web) {
        margin-right: 0;
    }

    .sndrll-bag-item {
        position: absolute;
        right: -6px;
        top: 2px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $color-p;
        color: $color-s;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        font-weight: 700;
        padding-top: 1px;
    }
}

.sndrll-user-nav {
    position: relative;

    li {
        display: inline-block;
        position: relative;

        ul {
            position: absolute;
            right: 0;
            z-index: 10;
            background: $color-white;
            padding: 20px;
            font-size: 14px;
            text-align: right;
            min-width: 170px;
            display: none;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            li {
                display: block;
                margin-bottom: 6px;

                &:last-child {
                    margin-top: 6px;
                    padding-top: 6px;
                    border-top: 1px solid $color-mid-gray;
                }
            }

            a {
                display: block;
                padding: 4px 0;
            }

            &.opened {
                display: block;
            }
        }
    }

    a {
        display: inline-block;
    }
}

.sndrll-nav,
.sndrll-user-nav,
.sndrll-user-favs {
    @include breakpoint(web) {
        display: none;
    }
}

.sndrll-mobile-menu {
    position: fixed;
    left: -300px;
    top: 0;
    bottom: 0;
    background: $color-white;
    padding: 16px 20px 20px 20px;
    z-index: 20;
    width: 300px;
    overflow-y: auto;

    @include transition;

    &.opened {
        left: 0;
    }

    .sndrll-mobile-menu-close {
        font-size: 24px;
        position: absolute;
        right: 0;
        top: 4px;
        padding: 10px;
    }

    ul {
        margin-top: 20px;

        li {
            display: block;
            margin-bottom: 3px;
            padding-bottom: 3px;

            &:not(:last-child) {
                border-bottom: 1px solid rgba($color-mid-gray, .25);
            }
        }

        a {
            display: block;
            padding: 6px 0;
            font-weight: 700;
            letter-spacing: 0.04em;

            &.active {
                background-color: $color-p;
                padding-left: 6px;
            }
        }

        ul {
            margin: 0 0 0 20px;
        }
    }
}

.sndrll-site-search {
    background-color: $color-white;
    position: absolute;
    right: 0;
    left: 0;
    top: -200px;
    padding: 14px 0;

    @include transition;

    &.opened {
        top: 0;
    }

    .sndrll-wrapper,
    .sndrll-site-search-form {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: space-between;
    }

    .sndrll-site-search-form {
        position: relative;
        width: calc(100% - 48px);
    }

    input {
        padding: 10px;
    }

    button {
        background: none;
        border: 0;
        font-size: 24px;
        position: absolute;
        right: 4px;
        top: calc(50% + 1px);
        transform: translateY(-50%);
    }

    .sndrll-site-search-close {
        font-size: 18px;
        padding: 6px;
        margin-left: 4px;
    }

    @include breakpoint(web) {
        padding: 8px 0;
    }

}

.sndrll-user-bag {
    display: none;
    position: absolute;
    right: 10px;
    top: 70px;
    z-index: 10;
    width: 280px;
    background: $color-white;
    padding: 16px;
    border: 1px solid $color-light-gray;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);

    &.opened {
        display: block;
    }

    @include breakpoint(web) {
        top: 56px;
    }

    ul li {
        position: relative;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($color-mid-gray, .25);

        a {
            display: flex;
            flex-flow: row wrap;
        }
    }

    &-remove-product {
        background: transparent;
        border: 0;
        cursor: pointer;
        font-size: 14px;
        padding: 4px;
        position: absolute;
        top: 0;
        right: 0;
        color: $color-mid-gray;
        z-index: 4px;
    }

    &-desc {
        width: calc(100% - 78px);
        margin-left: 6px;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
    }

    /*.brand-name{
        font-size: 12px;
        color: $color-mid-gray;
        line-height: 1.3;
        margin-bottom: .25em;
    }*/
}