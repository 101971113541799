.optiyol-resizable-list {
  width: auto;
  .list-blocker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 1000;
    padding: 1rem 0;
    box-sizing: border-box;
    background-color: rgba(#101725, 0.5);
    pointer-events: none;
  }
  .divider {
    z-index: -1;
  }
}
.optiyol-list {
  background-color: #fff;
  border-right: 1px solid $color-light-gray;
  .divider {
    width: 100%;
    height: 1px;
    background-color: #f0f0f3;
  }
  .fas {
    height: 35px;
    width: 35px;
    border-radius: 6px;
    background-color: rgba(129, 129, 165, 0.1);
    color: #8181a5;
    text-align: center;
    line-height: 35px;
    margin: 0 4px;
    font-size: 16px;
    cursor: pointer;
    &.simple {
      width: unset !important;
      height: unset !important;
      margin: 0;
      line-height: unset !important;
      background-color: unset !important;
      border: none !important;
      display: unset !important;
    }
    &.transparent {
      background-color: transparent !important ;
    }
    &.red {
      background-color: rgba(255, 128, 139, 0.1);
      color: #ff808b;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    padding: 19px 20px;
    .header-text {
      font-size: 18px;
      font-weight: bold;
      margin-right: 5px;
    }
    &.border {
      border-bottom: 1px solid $color-light-gray;
    }
    .icons {
      margin-left: auto;
    }
  }
  .search-bar {
    padding: 9.5px 15px;
    display: flex;
    align-items: center;
    font-size: 12px;
    input {
      flex: 1;
      border: 0;
      &:focus {
        border: 0;
        outline: 0;
      }
    }
    .fas {
      background: none;
    }
  }
  .sort-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 18px;
    color: #475569;
    font-size: 13px;
    background-color: #f9f9f9;
    width: 100%;
  }
  .selected-rows-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-top: 1px solid $color-light-gray;
  }
  .list-item {
    padding: 15px 15px 21px 15px;
    .fas {
      font-size: 19px;
      &-error {
        background-color: rgba(224, 32, 32, 0.08);
        color: #e02020;
      }
      &-completed {
        background-color: rgba(109, 212, 0, 0.1);
        color: #6dd400;
      }
      &-draft {
        background-color: rgba(244, 190, 94, 0.15);
        color: #fdbd48;
      }
      &-sentfield {
        background-color: #eff2fe;
        color: 8181a5;
      }
      &-pending {
        background-color: #fef6e7;
        color: #f4be5e;
      }
    }
  }
  .route-id {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    position: relative;
    .dot {
      right: 3px;
      top: -2px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      position: relative;
      border: 2px solid white;
      &.dot-green {
        background-color: #7ce7ac;
      }
      &.dot-orange {
        background-color: #f4be5e;
      }
    }
    .edited {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -7px;
      top: -7px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: 2px solid white;
      background-color: $color-red;
      .fas {
        font-size: 10px;
        color: #fff;
      }
    }
  }
  .tracking-list-item {
    @include breakpoint(tablet) {
      padding: 10px 20px 0px 18px;
      div:not(.badge) {
        display: none;
      }
      .fas {
        display: none;
      }
      .row {
        display: flex !important;
      }
      .second-row {
        margin-top: 0 !important;
      }
      .statuses {
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;

        * {
          display: unset !important;
          margin-right: 0 !important;
        }
        .status-circle {
          display: inline-block !important;
          margin-right: 3px !important;
        }
      }
    }
    position: absolute;
    background-color: #fff;
    outline: 1px solid #f0f0f3;
    width: 100%;
    @include transition($duration: 1s);
    .recommend-reop {
      position: absolute;
      top: -18px;
      right: 150px;
      color: $color-dark-gray;
    }
    .capacity-info {
      top: -15px;
      right: 90px;
      font-size: 16px;
      color: $color-dark-gray;
      .fas {
        color: $color-dark-gray;
      }
    }
    .order-count {
      top: -15px;
      right: 0px;
      font-size: 16px;
      color: $color-orange;
    }
    .compliance-pulse-red {
      animation: pulsered 1.5s;
      animation-iteration-count: 5;
    }
    .compliance-pulse-green {
      animation: pulsegreen 1.5s;
      animation-iteration-count: 5;
    }
  }
  .single-plan-list-item {
    @include breakpoint(tablet) {
      div:not(.route-id) {
        display: none;
      }
      .route-id {
        position: relative;
        width: 50px;
        text-align: center;
        .fas {
          margin: 0 !important;
        }
        .dot {
          position: absolute;
          left: -3px;
          top: -3px;
        }
        .route-seq-number-container {
          margin-top: -7px;
        }
      }
      .route-id * {
        display: block !important;
      }
    }
    padding: 15px 20px 10px 20px;
    position: absolute;
    background-color: #fff;
    outline: 1px solid #f0f0f3;
    width: 100%;
    .svg-box {
      width: 25px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fas {
      height: 35px;
      width: 20px;
      border-radius: 6px;
      background-color: transparent;
      color: $color-mid-gray;
      text-align: center;
      line-height: 35px;
      font-size: 16px;
    }
    .bar {
      width: 70px;
      height: 5px;
      border-radius: 5px;
      background-color: $color-light-gray;
      .bar-colored {
        height: 5px;
        border-radius: 5px;
      }
    }
    .summary-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .dashed-line-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 5px;
      margin-right: 10px;
      .circle {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: white;
        border: 1px solid $color-dark-gray;
        &-filled {
          background-color: $color-dark-gray;
        }
      }
      .dashed-line {
        height: 11px;
        width: 1px;
        border-left: 1px dashed $color-dark-gray;
      }
    }
  }
  .timeline-header {
    display: flex;
    padding: 8px 15px;
    align-items: center;
    justify-content: space-between;
    .fas {
      background-color: transparent;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid #475569;
      color: #475569;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .time-violation-list {
    position: absolute;
    font-size: 11px;
    bottom: 55px;
    left: 115px;
    background-color: rgba(250, 100, 0, 0.05);
    margin-left: 4px;
    color: #fa6400;
    padding: 2px 6px;
    border-radius: 10px;
  }
  .timeline-grouped-list {
    background-color: #f6f6f6;
    padding: 10px 10px;
    margin: 20px 0;
    border-radius: 5px;
    position: relative;
    #arrival-departure-time {
      @include transition;
      transition-delay: 0.4s;
    }
    .icons {
      display: flex;
      margin-left: auto;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      right: 20px;
      @include transition;
      transition-delay: 0.4s;
      .fas {
        background-color: #fff;
        width: 20px;
      }
    }
    &:hover {
      .icons {
        visibility: visible;
        opacity: 1;
      }
      #arrival-departure-time {
        visibility: hidden;
        opacity: 0;
      }
    }
    .group-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #475569;
      padding: 3px 10px;
      position: relative;
      .time {
        position: absolute;
        right: 20px;
      }
    }
    .route-point {
      width: 37px;
      height: 37px;
      border-radius: 50%;
      border: 1px solid #cbd5e1;
      position: relative;
      padding: 2px;
      margin-right: 10px;
      .standart-inner {
        border-radius: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .fas {
          color: #64748b;
          background-color: transparent;
          cursor: auto;
        }
      }
      &-checked {
        border-color: $color-p;
        .inner-circle {
          background-color: $color-p;
          color: $color-p;

          &::before {
            position: absolute;
            left: 13px;
            top: 4px;
            content: '\02143';
            color: #fff;
            transform: rotate(40deg) scale(1.2);
          }
        }
      }
      .inner-circle {
        border-radius: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        cursor: pointer;
        @include transition;
        &.with-hover {
          &:hover {
            background-color: $color-p;
            color: $color-p;
          }
          &:hover::before {
            position: absolute;
            left: 13px;
            top: 4px;
            content: '\02143';
            color: #fff;
            transform: rotate(40deg) scale(1.2);
          }
        }
      }
    }
    .item {
      border-radius: 6px;
      display: flex;
      align-items: center;
      position: relative;
      .time {
        position: absolute;
        right: 20px;
      }
    }
    .distance {
      position: absolute;
      top: -20px;
      left: 20px;
      background: #fff;
      z-index: 1;
      font-size: 12px;
      color: #64748b;
      font-weight: bold;
    }
  }
  .route-timeline-list-item {
    display: flex;
    align-items: center;
    &:hover {
      .icons {
        visibility: visible;
        opacity: 1;
      }
      #arrival-departure-time {
        visibility: hidden;
        opacity: 0;
      }
    }
    .time {
      position: absolute;
      right: 20px;
    }
    padding: 0 10px 0 20px;
    height: 80px;
    position: relative;

    .distance {
      position: absolute;
      top: -10px;
      left: 20px;
      background: #fff;
      z-index: 2;
      font-size: 12px;
      color: #64748b;
      font-weight: bold;
    }
    .next-day-info {
      position: absolute;
      bottom: -8px;
      left: 120px;
      width: 100%;
      background: #283547;
      border-radius: 3px;
      z-index: 1;
      font-size: 12px;
      color: #fff;
      font-weight: bold;
      padding-left: 10px;
    }
    .timeline {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      .line {
        flex: 1;
        width: 1px;
      }
      .route-point {
        width: 37px;
        height: 37px;
        border-radius: 50%;
        border: 1px solid #cbd5e1;
        position: relative;
        padding: 2px;
        .standart-inner {
          border-radius: 50%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          .fas {
            color: #64748b;
            background-color: transparent;
            cursor: auto;
          }
        }
        &-checked {
          border-color: $color-p;
          .inner-circle {
            background-color: $color-p;
            color: $color-p;

            &::before {
              position: absolute;
              left: 13px;
              top: 4px;
              content: '\02143';
              color: #fff;
              transform: rotate(40deg) scale(1.2);
            }
          }
        }
        .inner-circle {
          border-radius: 50%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          cursor: pointer;
          @include transition;
          &.with-hover {
            &:hover {
              background-color: $color-p-half;
              color: $color-p-half;
            }
            &:hover::before {
              position: absolute;
              left: 13px;
              top: 4px;
              content: '\02143';
              color: #fff;
              transform: rotate(40deg) scale(1.2);
            }
          }
        }
      }
    }
    #arrival-departure-time {
      @include transition;
      transition-delay: 0.4s;
    }
    .time-violation {
      z-index: 2;
      font-size: 11px;
      position: absolute;
      right: 12px;
      bottom: -12px;
      background-color: #feefe5;
      color: #fa6400;
      padding: 5px 9px;
      border-radius: 10px;
    }
    .icons {
      display: flex;
      margin-left: auto;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      right: 20px;
      @include transition;
      transition-delay: 0.4s;
      .fas {
        width: 20px;
      }
    }
  }
  .planning-singleplan-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    width: 100%;
    height: 60px;
    font-size: 13px;
    color: #475569;
    border-top: 1px solid #f1f5f9;
    .dot {
      width: 6px;
      height: 6px;
      border-radius: 3px;
      &.dot-green {
        background-color: #7ce7ac;
      }
      &.dot-orange {
        background-color: #f4be5e;
      }
    }
    .fas {
      font-size: 16px;
      padding: 6px;
      background-color: #f2f2f6;
      border-radius: 5px;
      margin-right: 5px;
      &:hover {
        background-color: #dadada;
      }
      &.color-p {
        background-color: #195ef6;
        color: white;
        &.disable {
          background-color: #004cf1;
          cursor: not-allowed;
        }
        &:hover {
          background-color: #004cf1;
        }
      }
      &.color-r {
        background-color: #f04444;
        color: white;
        &.disable {
          background-color: #d33c3c;
          cursor: not-allowed;
        }
        &:hover {
          background-color: #d33c3c;
        }
      }
    }
    @include breakpoint(tablet) {
      > :first-child {
        display: none !important;
      }
    }
  }
  &.planning_route_list {
    @include breakpoint(tablet) {
      width: 80px !important;
      .sort-bar {
        display: none;
      }
      .search-bar {
        display: none;
      }
      .optiyol-list-header {
        display: none;
      }
    }
  }
  &.tracking_route_list {
    @include breakpoint(tablet) {
      width: 70px !important;
      .sort-bar {
        display: none;
      }
      .search-bar {
        display: none;
      }
      .optiyol-list-header {
        .header-text,
        .count-text {
          display: none;
        }
        #optiyol-modal-track-filter {
          .filter-part {
            flex-direction: column;
            align-items: baseline;
          }
          .scenario-vehicles {
            display: none;
          }
        }
      }
    }
  }
  &.collapsable {
    position: relative;
    @include transition;
    width: unset;
    .collapse-icon {
      position: absolute;
      top: 100px;
      right: -17px;
      background-color: $color-mid-gray;
      color: #fff;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 13px;
    }
    &.closed {
      width: 20px;
      .collapse-icon {
        visibility: visible;
      }
      * {
        visibility: hidden;
      }
    }
  }
}
.count-text {
  border-radius: 20px;
  padding: 4px 7px;
  min-width: 30px;
  min-height: 25px;
  background-color: rgba(94, 129, 244, 0.1);
  color: #5e81f4;
  text-align: center;
  line-height: 24px;
  margin-left: 5px;
  &.dark {
    padding: 2px 12px;
    color: white;
    background-color: #185cf7;
  }
  &.red {
    padding: 2px 12px;
    color: white;
    background-color: $color-red;
  }
}

.dragging-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: $color-p;
  border-radius: 50%;
  color: #fff;
  width: 35px;
  height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 3px #195ef677;
}
.drag-error {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  padding: 10px 20px;
  height: 57px;
  background-color: rgba(224, 32, 32, 1);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .fas {
    margin-right: 0px;
    font-size: 30px;
  }
}
.drag-handle {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
}
.order-point-count-search-plan {
  display: flex;
  border: 1.5px solid $color-light-gray;
  border-radius: 6px;
  padding: 12px 6px 12px 9px;
  margin: -7px 0px;
}

@keyframes pulsered {
  0% {
    color: #f4aaaa;
    font-size: 16px;
  }
  50% {
    color: rgb(255, 0, 0);
    font-size: 18px;
  }
  100% {
    font-size: 16px;
    color: #f4aaaa;
  }
}
@keyframes pulsegreen {
  0% {
    color: #83e086;
    font-size: 16px;
  }
  50% {
    color: rgb(0, 255, 0);
    font-size: 18px;
  }
  100% {
    color: #83e086;
    font-size: 16px;
  }
}

.plan-timeline {
  @include breakpoint(mobile) {
    display: none;
  }
}

.courier-tracking-timeline {
  &-route {
    border: 1px solid $color-mid-gray;
    border-radius: 10px;
    padding: 3px;
    margin: 10px 5px;
  }
  &-group {
    border: 1px solid $color-light-gray;
    background-color: $color-base-gray;
    border-radius: 10px;
    margin: 30px 10px;
    padding: 10px 10px 0 10px;
    position: relative;
    .route-title {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      width: max-content;
      top: -23px;

      z-index: 0;
      &::before {
        content: ' ';
        border-top: 3px solid $color-mid-gray;
        position: absolute;
        width: 30px;
        top: 40%;
        right: -40px;
        z-index: -1;
      }
      &::after {
        content: ' ';
        border-top: 3px solid $color-mid-gray;
        position: absolute;
        width: 30px;
        top: 40%;
        left: -40px;
        z-index: -1;
      }
    }
    .distance {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      width: max-content;
      top: -23px;
      font-size: 10px;
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: -8px;
        height: 8px;
        width: 2px;
        z-index: 10;
        background-color: $color-mid-gray;
      }
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 14px;
        height: 8px;
        width: 2px;
        z-index: 10;
        background-color: $color-mid-gray;
      }
    }
  }
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    .order-badge {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 1px solid $color-mid-gray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
