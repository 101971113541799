@import '../base/base.breakpoints';
.table-container {
  $paddingHeight: 50px;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  &.pagination {
    height: calc(100% - #{$paddingHeight});
  }
  &.entity-count-info {
    height: calc(100% - 80px);
  }
}
table {
  margin-top: -3px;
  border-collapse: separate;
  border-spacing: 0px 3px;
}

.sticky-table-left {
  position: sticky;
  left: 0px;
}

.sticky-table-right {
  position: sticky;
  right: 0px;
}
table td {
  padding: 14px 12px;
}

table th {
  text-align: start;
  font-weight: 500;
  color: #8181a5;
  font-size: 12px;
  vertical-align: middle;
  padding: 12px 10px;
  position: sticky;
  top: 0;
  background: #fbfbfd;
  white-space: nowrap;
  i {
    font-size: 16px;

    &.filter-active {
      position: relative !important;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #185cf7;
      }
    }
  }
}

table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #fff;
  .route-id {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
table td > div {
  background: #fff;
  white-space: nowrap;
  overflow: hidden;
}

.sticky-columns {
  position: sticky;
}

.pos-unset {
  position: unset !important;
}

.left-0 {
  left: 0px;
}

.right-0 {
  right: 0px;
}
.header-background {
  position: absolute;
  height: 20px;
  top: 10px;
  right: 0px;
  left: 0px;
  background-color: #fbfbfd;
}
.more-action-ellipsis {
  border: 1px solid #ececf2;
  border-radius: 5px;
  color: #8181a5;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  &:hover {
    background-color: #ececf2;
  }
}

tbody tr td:first-child {
  border: solid #ececf2;
  border-width: 1px 0px 1px 1px;
  border-radius: 8px 0px 0px 8px;
  &.line {
    & .thin {
      padding: 4px 12px;
    }
    border: solid #ececf2;
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
  }
}

tbody tr td:last-child {
  border: solid #ececf2;
  border-radius: 0px 8px 8px 0px;
  border-width: 1px 1px 1px 0px;
  &.line {
    &.thin {
      padding: 4px 12px;
    }
    border: solid #ececf2;
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
  }
}

tbody tr td:only-child {
  border: solid #ececf2;
  border-radius: 0px 8px 8px 0px;
  border-width: 1px 1px 1px 1px;
  &.line {
    &.thin {
      padding: 4px 12px;
    }
    border: solid #ececf2;
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
  }
}

tbody tr td {
  border: solid #ececf2;
  vertical-align: middle;
  border-width: 1px 0px 1px 0px;
  &.late {
    background-color: #fef6ff;
    border-color: #eab9f3;
    div {
      background-color: transparent;
    }
  }
  &.today {
    background-color: #f7fef7;
    border-color: #83f580;
    div {
      background-color: transparent;
    }
  }
  &.missing {
    background-color: #fefcf7;
    border-color: #f5d680;
    div {
      background-color: transparent;
    }
  }
  &.warning {
    background-color: #fef6ff;
    border-color: #eab9f3;
    div {
      background-color: transparent;
    }
  }
  &.grey {
    background-color: #d3d3d3;

    div {
      background-color: transparent;
    }
  }
  &.deleted {
    background-color: #f7ebeb67;
    border-color: #f5c3c3;
    div {
      background-color: transparent;
    }
  }
  &.line {
    &.thin {
      padding: 4px 12px;
    }
    border: solid #ececf2;
    border-width: 0px 0px 1px 0px;
  }
}

table {
  .check-box-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    $boxHeight: 8px;
    position: absolute;
    top: calc(50% - #{$boxHeight});
    left: 8px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #ececf2;
  }

  .container:hover input ~ .checkmark {
    background-color: #eee;
  }

  .container input:checked ~ .checkmark {
    background-color: #185cf7;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.optiyol-pagination-datatable {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ul {
    display: flex;
    flex-direction: row;
    @include breakpoint(mobile) {
      li {
        display: none;
      }
    }
  }
  .action {
    @include breakpoint(mobile) {
      &.first,
      &.last {
        display: none;
      }
    }
    @include breakpoint(tablet) {
      span {
        display: none;
      }
    }
  }

  .item {
    color: #8181a5;
    font-size: 14px;
    background-color: #f5f5fa;
    margin: 0px 2px;
    font-weight: 500;
    padding: 6px;
    cursor: pointer;
    border-radius: 5px;

    &.selected {
      a {
        color: #185cf7;
      }
    }
    &.disabled {
      opacity: 0.6;
      pointer-events: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      color: #8181a5;
    }
  }
}

.status-cell {
  font-size: 19px;
  .fas {
    height: 35px;
    width: 35px;
    border-radius: 6px;
    background-color: rgba(129, 129, 165, 0.1);
    color: #8181a5;
    text-align: center;
    line-height: 35px;
    margin: 0 4px;
    font-size: 16px;
    cursor: pointer;

    &-error {
      background-color: rgba(224, 32, 32, 0.08);
      color: #e02020;
    }
    &-completed {
      background-color: rgba(109, 212, 0, 0.1);
      color: #6dd400;
    }
    &-draft {
      background-color: rgba(244, 190, 94, 0.15);
      color: #fdbd48;
    }
    &-sentfield {
      background-color: #eff2fe;
      color: 8181a5;
    }
    &-pending {
      background-color: #fef6e7;
      color: #f4be5e;
    }
  }
}

.checked-count {
  color: #8181a5;
  span {
    font-weight: 500;
    color: #185cf7;
  }
}

.checked-bulk-action-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f6;
  border-radius: 8px;
  margin-right: 10px;
  font-size: 20px;
  i {
    color: #8181a5;
  }
  &.red {
    background-color: #ffebed;
    i {
      color: #ff808b;
    }
  }
}

.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 20px;
  font-weight: 300;
  position: absolute;
  top: 204px;
  left: 0px;
  &.static {
    position: static;
    height: 100%;
  }
}

.table-with-td-wrap {
  td {
    white-space: inherit;
    vertical-align: middle;
  }
}
.date-picker-size-class {
  font-size: 9px !important;
}

.filter-box-table-header {
  position: absolute;
  left: 5px;
  top: 60px;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 2px 2px 10px #9292c5;
  width: 200px;
  z-index: 1421423;
  .title {
    color: #8181a5;
    font-size: 12px;
    font-weight: 500;
    width: 166px;
  }
  .search-bar {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: relative;
    padding: 0px;
    input {
      flex: 1;
      border: 1px solid #9292c5;
      border-radius: 6px;
      padding: 6px;
      padding-right: 25px;
      width: 1px;
      &:focus {
        border: 1px solid #185cf7;
        outline: 0;
      }
    }
    .fas {
      background: none;
      position: absolute;
      right: 5px;
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    div {
      margin-top: 10px;
      margin-left: 15px;
      cursor: pointer;
      color: #8181a5;
      font-size: 12px;
      font-weight: 500;
    }
    .ok {
      color: #185cf7;
    }
  }
}
.expand-icon-style {
  margin-right: 5px;
  color: #8181a5;
  border: 1px solid #ececf2;
  border-radius: 6px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
