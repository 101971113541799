.filter-structured {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 6px 0;
  .clear {
    height: 35px;
    display: flex;
    align-items: center;
    .header {
      padding: 7px 12px;
      border-radius: 8px;
      color: $color-mid-gray;
      font-weight: 600;
    }
  }
  .filter-item {
    position: relative;
    cursor: pointer;
    margin: 3px;

    .header {
      font-size: 13px;
      padding: 8px 14px;
      border-radius: 8px;
      background-color: #fbfbfd;
      &.active {
        color: #fff;
        background-color: #334155;
      }
    }
    .filter-box {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      min-width: 230px;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 50px;
      left: 0;
      background-color: #fff;
      border: 1px solid $color-light-gray;
      border-radius: 8px;
      padding: 10px 14px;
      z-index: 10;
      &.open {
        visibility: visible;
        opacity: 1;
        display: flex;
        flex-direction: column;
      }
      &.right {
        left: unset;
        right: 0 !important;
      }

      .filter-item-inner {
        border: 1px solid #cdcecd;
        border-radius: 3px;
        min-height: 38px;
        max-height: 38px;
        min-width: 140px;
        margin-top: 10px;
        transition: border 0.2s;
        .DatePicker {
          width: 100%;
        }
        .DatePicker__input {
          text-align: left;
        }
        &.date {
          max-width: 120px;
        }
        &-empty {
          margin-top: 10px;
          min-height: 32px;
          max-height: 32px;
        }
        &:hover {
          border-color: #7986cb;
        }
        &:focus-within {
          border-color: #7986cb;
          box-shadow: 0 0 0 2px rgba(121, 134, 203, 0.4);
        }
        input {
          background: transparent;
          color: #555e68;
          font-size: 14px;
          padding: 0 10px;
          width: 100%;
          border: none;
          outline: none;
          height: 36px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
