.week-day-select-container {
  border: 1px solid #f5f5fa;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .days-header {
    background: #f6f6fa;
    display: flex;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    .day-title {
      width: 120px;
      text-align: center;
      color: #8181a5;
      padding: 10px 0px;
      &.w100px {
        width: 100px;
      }
    }
  }
  .days-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    .footer-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      color: white;
      &.dont-go {
        background-color: #e02020;
      }
      &.go {
        background-color: #26f718;
      }
      &.neutral {
        background-color: #e1e1f7;
      }
    }
  }
}
.template-inline-edit-string {
  width: 100px;
  border: solid #ebebeb;
  border-width: 0px 0px 1px 0px;
  padding: 4px;
  text-align: center;
  &:focus {
    outline: none;
  }
}

.day-rule-badge {
  padding: 2px 10px;
  background-color: #304157;
  color: 'white';
  border-radius: 8px;
  position: absolute;
  right: -10px;
  bottom: -4px;
  font-size: 10px;
}

.day-rule-remove-cross {
  color: #304157;
  position: absolute;
  top: -9px;
  right: -14px;
}

.days-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #f5f5fa;
  .option-div {
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    &.w100px {
      width: 100px;
    }
    .day-select {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      width: 35px;
      height: 35px;
      border-radius: 50%;

      &.dont-go {
        background-color: #e02020;
      }
      &.go {
        background-color: #26f718;
      }
      &.neutral {
        background-color: #e1e1f7;
      }
    }
  }
}
