.feedback-form {
  .context {
    border: 1px solid #ececf2;
    border-radius: 8px;
    padding: 14px 20px;
    display: flex;
    margin-bottom: 10px;
    .form-element {
      border: 1px solid #ececf2;
      background-color: #fff;
      border-radius: 8px;
      width: 125px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 14px;
      color: $color-mid-gray;
      font-weight: 500;
      margin: 10px 0;
      &.dragging-on-form {
        border: 1px dashed $color-green;
      }
      &:first-child {
        margin-top: 0;
      }
      .icon {
        font-size: 33px;
      }
    }
    .form-list {
      flex: 1;
      margin-left: 20px;
      background-color: #f5f4fb;
      border-radius: 8px;
      padding: 10px 20px;
      overflow-y: scroll;

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;
        background-color: #fff;
        padding: 5px 8px;
        border-radius: 8px;
        .icon {
          width: 100px;
          text-align: center;
          font-weight: 500;
          .fas {
            font-size: 33px;
          }
          color: $color-mid-gray;
        }
        .header-input {
          flex: 1;
          margin: 0 10px;
        }
      }
    }
  }
}

.mobile-app-form {
  .form-element {
    border: 1px solid #ececf2;
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    &.tall {
      height: 80px;
    }
    &.dragging-on-form {
      border: 1px dashed $color-p;
    }
    .icon-box {
      font-size: 18px;
      .fas {
        margin: 0 5px;
      }
    }
  }
}

.tree-node__leaf,
.tree-node__branch {
  display: flex;
  align-items: center;
}
.element-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  .element {
    outline: 1px solid $color-s;
    border-radius: 10px;
    padding: 20px 10px;
    width: 230px;
    height: 250px;
    @include transition;
    &.selected {
      outline: 4px solid $color-green;
    }
  }
}
