/**/
.bg-white {
  background-color: $color-white !important;
}

.bg-color-light-gray {
  background-color: $color-light-gray !important;
}

.bg-color-mid-gray {
  background-color: $color-mid-gray !important;
}
.bg-color-light-green {
  background-color: $color-light-green !important;
}
.bg-color-green {
  background-color: $color-green !important;
}
.bg-color-dark-gray {
  background-color: $color-dark-gray !important;
}
.bg-color-p {
  background-color: $color-p !important;
}
.bg-color-red {
  background-color: $color-red !important;
}
.bg-color-orange {
  background-color: $color-orange !important;
}
.bg-color-s {
  background-color: $color-s !important;
}
.bg-red {
  background-color: $color-red !important;
}
.bg-orange {
  background-color: $color-orange !important;
}
.text-white {
  color: $color-white !important;
}

.text-color-p {
  color: $color-p !important;
}

.text-color-s {
  color: $color-s !important;
}
.text-color-light-green {
  color: $color-light-green !important;
}

.text-black {
  color: $color-black !important;
}

.text-green {
  color: $color-green !important;
}
.text-orange {
  color: $color-orange !important;
}

.text-red {
  color: $color-red !important;
}

.text-light-gray {
  color: $color-light-gray !important;
}
.text-mid-gray {
  color: $color-mid-gray !important;
}
.text-dark-gray {
  color: $color-dark-gray !important;
}
