.form-label {
  display: block;
  line-height: 1;
  user-select: none;
  margin-bottom: 6px;
  font-weight: 300;
  color: #8181a5;
}

fieldset {
  position: relative;

  .icon-link {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: $color-p;
    font-size: 18px;
  }

  .form-input.with-icon-link {
    width: calc(100% - 24px);
  }
}

/**
  * Component Element
  */

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.form-input,
.form-textarea,
.form-select {
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid #cbd5e1;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  outline: none;
  outline-style: none;
  padding: 8px 25px 8px 3px;
  height: 38px;
  position: relative;
  width: 100%;
  -webkit-appearance: none;

  &.w-border {
    border: 1px solid $color-s;
  }

  &.error {
    border-bottom: 1px solid $color-red !important;
  }
  &.small {
    padding: 9px 10px;
  }
  &:focus {
    border-bottom: 1px solid $color-p;
  }
}
::placeholder {
  color: rgba(#334155, 0.4);
}

::-ms-input-placeholder {
  color: rgba(#334155, 0.4);
}

::-ms-input-placeholder {
  color: rgba(#334155, 0.4);
}

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 6px;
  padding-right: 25px;
  text-align: right;
  font-weight: 400;
  border: 0;
  &:after {
    color: #000;
    content: '▾';
    display: block;
    height: 14px;
    position: absolute;
    right: 24px;
    top: 26px;
    width: 14px;
  }
}

.form-textarea {
  font-size: 16px;
  min-height: 140px;
  line-height: 1.6;
  resize: none;
}

.file-upload {
  position: relative;
  display: inline-block;

  .file-upload__label {
    display: block;
    padding: 12px 30px;
    color: $color-p;
    background: $color-s;
    border-radius: 4px;
    transition: background 0.3s;

    &:hover {
      cursor: pointer;
      background: $color-p;
      color: $color-s;
    }
  }

  .file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;

    .file-upload__label {
      &:hover {
        cursor: default;
        color: $color-p;
        background: $color-s;
      }
    }
  }
}

.form-radio,
.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: $color-light-gray;
  color: #666;
  height: 18px;
  width: 18px;
  margin: 0;
  border-radius: 0;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  @include transition;

  &:checked::before {
    position: absolute;
    left: 5px;
    top: 1px;
    content: '\02143';
    transform: rotate(40deg);
  }

  &:hover {
    border-color: #000;
  }

  &:checked {
    background-color: $color-p;
    border-color: $color-s;
    color: $color-white;
  }
  &.red {
    &:checked {
      background-color: $color-red;
      border-color: $color-s;
      color: $color-white;
      &:checked::before {
        position: absolute;
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        left: 5px;
        top: 1px;
        transform: rotate(40deg);
      }
    }
  }
  &.orange {
    &:after {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      content: '\2013';
      border-radius: 3px;
      position: absolute;
      height: 18px;
      width: 18px;
      background-color: $color-orange;
      border-color: $color-s;
      color: $color-white;
    }
  }
}

.form-radio {
  border: 2px solid $color-s;
  border-radius: 50%;
  top: 0px;

  @include transition;

  &:before {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: '';
    background: $color-white;
    border-radius: 50%;
    @include transition;
  }

  &:checked {
    background-color: transparent;
    border-color: $color-black;
    color: #fff;
    z-index: 2;
    @include transition;

    &:before {
      content: '';
      background: $color-p;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.form-checkbox {
  border-radius: 3px;
}

.error-text {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: $color-red;
  margin-top: 0.25rem;
}

button {
  font-family: $font-body;
  font-size: 16px;

  &:focus {
    outline: 0;
  }
}

.anatolia-toggle {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .anatolia-toggle-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .anatolia-toggle-btn {
    outline: 0;
    display: block;
    width: 40px;
    height: 20px;
    position: relative;
    cursor: pointer;
    user-select: none;
    &:after,
    &:before {
      position: relative;
      display: block;
      content: '';
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .anatolia-toggle-btn:after {
    left: 50%;
  }
}

// themes
.anatolia-toggle-light {
  + .anatolia-toggle-btn {
    background: #cdddfe;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    box-shadow: inset 0 0 4px rgba(#001c57, 0.4);
    &:after {
      border-radius: 50%;
      background: #fff;
      transition: all 0.2s ease;
      box-shadow: 0 1px 1px rgba(#001c57, 0.4);
    }
  }

  &:checked + .anatolia-toggle-btn {
    background: #195ef6;
  }
}

.optiyol-form-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.input-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 16px;
  color: #1c1d21;
}
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

input:read-only {
  background-color: $color-light-gray;
}
input {
  &.read-only {
    background-color: $color-base-gray;
  }
}
label {
  &.required {
    position: relative;
    &::after {
      content: ' *';
      color: black;
    }
  }
}

.tags-input-tags {
  display: inline-flex;
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 2px;
  color: #333;
  font-size: 85%;
  margin: 2px;
  padding-left: 6px;
}
.react-tagsinput-remove-custom {
  z-index: 10;
  cursor: pointer;
  align-items: center;
  border-radius: 2px;
  display: flex;
  padding: 1px 7px;
  margin-left: 2px;
  font-size: 16px;

  &:hover {
    background-color: #ffbdad;
    color: #de350b;
  }
  &:after {
    content: '×';
    font-weight: 600;
  }
}
.react-tagsinput {
  background-color: #fff;
  border: unset;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}
.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #000;
  font-family: unset;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: unset;
  box-sizing: content-box;
}

.general-forms {
  flex: 1;
  display: flex;
  flex-direction: column;
  &.border {
    border: 1px solid $color-mid-gray;
    border-radius: 10px;
  }
  .form-group {
    margin: 10px 0;
    &-header {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      margin-top: 4px;
    }
    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      .column {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding-right: 24px;
        margin: 8px 0;
        label {
          font-weight: 500;
          &.required:after {
            content: ' *';
          }
        }
        input::placeholder {
          color: $color-mid-gray;
          font-weight: 300;
        }
        .react-select__placeholder {
          color: $color-mid-gray;
        }
        .error {
          color: $color-red;
          position: absolute;
          bottom: -19px;
          left: 0;
          font-size: 12px;
        }
        .form-icon {
          position: absolute;
          bottom: 10px;
          right: 30px;
        }
        .optiyol-modal-body {
          .input {
            .fas {
              position: initial;
            }
          }
        }
      }
    }
    .react-select {
      &__control {
        border-width: 0;
        border-bottom-width: 1px;
        box-shadow: unset;
        border-radius: 0;
        &--is-focused {
          border-width: 0;
          border-bottom-width: 1px;
        }
      }
    }
    .rc-time-picker-input {
      border-width: 0;
      border-bottom-width: 1px;
      border-radius: 0;
    }
  }
}
