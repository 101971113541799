.patchPrintStyle {
  .summary-visualization {
    overflow: initial;
    &-box {
      margin: 35px 0;
    }
    &-table {
      display: none;
    }
  }
}
.reports {
  .prev-next-box {
    display: flex;
    .item {
      position: relative;
      background-color: #fff;
      margin: 0 10px;
      padding: 6px 25px;
      border-radius: 10px;
      color: $color-mid-gray;
      cursor: pointer;
      .fas {
        font-size: 18px;
      }
    }
  }
  .summary-visualization {
    flex: 1;
    padding: 20px;
    .simple-box {
      width: 32%;
      margin: 10px;
      padding: 10px 15px;
      border: 1px solid $color-light-gray;
      border-radius: 12px;
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .bar {
        width: 100%;
        height: 3px;
        background-color: #7ce7ac;
        margin-top: 5px;
        border-radius: 3px;
      }
    }
    &-table {
      padding-top: 15px;
      /* border: 1px solid $color-light-gray; */
      border-radius: 12px;
    }
    &-box {
      width: 32%;
      margin: 10px;
      padding: 10px 15px;
      border: 1px solid $color-light-gray;
      border-radius: 12px;
      display: flex;
      flex-direction: column;

      .item-list {
        .item {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .item-point {
          border-radius: 50%;
          box-shadow: 0px 0px 0px 10px white;
          width: 3px;
          height: 3px;
        }
        .outer-circle {
          background: #385a94;
          border-radius: 50%;
          height: 11px;
          width: 11px;
          position: relative;
          .inner-circle {
            position: absolute;
            background: white;
            border-radius: 50%;
            height: 5px;
            width: 5px;
            left: 50%;
            top: 50%;
            transform: translate(-60%, -60%);
          }
        }
      }
      .custom-tooltip {
        background-color: #eeeeeea0;
        padding: 10px;
        border-radius: 10px;
        display: flex;
      }
      .capacity-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        .capacity-tab {
          color: $color-mid-gray;
          padding: 6px 12px;
          margin-right: 7px;
          border-radius: 10px;
          border-bottom: 1px solid $color-light-gray;
          cursor: pointer;
          &.active {
            border: 1px solid $color-light-gray;
            color: #000;
            font-weight: 500;
          }
        }
      }
    }
  }
  .eye {
    &-visible {
      .fa-eye {
        color: #195ef6;
      }
    }
    &-hidden {
      .fa-eye {
        color: #94a3b8;
      }
    }
  }
  .route-id {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 35px;
    height: 35px;
    color: #fff;
    position: relative;
    border-radius: 10px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .chart-column {
    flex: 31%;
    max-width: 31%;
  }
  .simple-column {
    flex: 31%;
    max-width: 31%;
  }
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media (max-width: 1350px) {
    .simple-column {
      flex: 45%;
      max-width: 45%;
    }
    .chart-column {
      flex: 45%;
      max-width: 45%;
    }
  }

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media (max-width: 1100px) {
    .simple-column {
      flex: 95%;
      max-width: 95%;
    }
    .chart-column {
      flex: 95%;
      max-width: 95%;
    }
  }
}

.shipping-list-warning {
  padding: 10px 20px;
  margin: 10px;
  border: 1px solid $color-orange;
  background-color: #f59e2623;
  border-radius: 10px;
}
