@import 'base/_base.variables.scss';
@import 'base/_base.reset.scss';
@import 'base/_base.breakpoints.scss';
@import 'base/_base.transition.scss';
@import 'base/_base.colors.scss';
@import 'base/_base.links.scss';
@import 'base/_base.images.scss';
@import 'base/_base.flex.scss';
@import 'base/_base.buttons.scss';
@import 'base/_base.typography.scss';
@import 'base/_base.forms.scss';
@import 'base/_base.modal.scss';
@import 'base/_base.header.scss';
@import 'base/_base.spacing.scss';
@import 'base/_base.input-tags.scss';
@import 'react-tagsinput/react-tagsinput.css';
@import 'components/dropdowns.scss';
@import 'components/autocomplete.scss';
@import 'components/optiyol-list.scss';
@import 'components/big-calendar.scss';
@import 'components/resizable-box.scss';
@import 'components/stepper.scss';
@import 'components/reports.scss';
@import 'components/data-table.scss';
@import 'components/orders-general.scss';
@import 'components/popover.scss';
@import 'components/infobubble.scss';
@import 'components/date-picker.scss';
@import 'components/filter-structured.scss';
@import 'components/filter.scss';
@import 'components/master-data.scss';
@import 'components/math-captcha.scss';
@import 'components/toast.scss';
@import 'components/tooltip.scss';
@import 'components/tracking.scss';
@import 'components/skeleton.scss';
@import 'components/switch.scss';
@import 'components/chat.scss';
@import 'components/settings.scss';
@import 'components/week-day-select.scss';
@import 'components/dashboard.scss';

.optiyol-header {
  background: $color-white;
  width: calc(100% - 60px);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow: 0 3px 6px rgba(87, 105, 27, 0.16);

  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;

  &-right {
    margin-left: auto;
    margin-right: 16px;

    li {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      color: #94a3b8;
      font-size: 20px;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      position: relative;
      @include transition;

      &:hover {
        background: #f1f5f9;
        color: #64748b;
      }

      &.active {
        background: #195ef6;
        color: #fff;
      }

      &.has-message {
        .optiyol-notif-red {
          width: 7px;
          height: 7px;
          background: $color-red;
          border-radius: 50%;
          position: absolute;
          right: 8px;
          top: 7px;
          z-index: 1;
        }
      }
    }
  }

  &-left {
    display: flex;
  }

  .optiyol-header-left-button {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    min-width: 200px;
    height: 60px;
    border-right: 1px solid #e2e8f0;
    padding: 12px 14px;

    i {
      width: 12px;
      height: 12px;
      font-size: 12px;
      color: #94a3b8;
    }

    .text {
      margin-left: 12px;
    }

    .text-top {
      width: 144px;
      font-weight: 700;
    }

    &:hover {
      i {
        color: #195ef6;
      }
    }
  }

  .optiyol-header-left-tab {
    padding: 7px 0 7px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid #e2e8f0;

    @include transition;

    .icon {
      font-size: 12px;
      padding: 14px;

      span {
        @include transition;
      }
    }

    .text {
      margin-right: 40px;
      font-weight: bold;
      line-height: 1.2;

      span {
        font-size: 12px;
        font-weight: normal;
        color: #64748b;
      }
    }

    &.optiyol-plan-name {
      flex-flow: column wrap;
      align-items: flex-start;
      justify-content: center;
      padding-right: 14px;

      .text {
        margin-left: 0;
      }
    }

    &:hover {
      background-color: #f1f5f9;

      .icon {
        color: #195ef6;
      }
    }

    &.active {
      background-color: #f1f5f9;

      .icon {
        color: #195ef6;

        span {
          transform: rotate(-135deg);
        }
      }
    }
  }

  @include breakpoint(tablet) {
    top: 60px;
  }
}

.optiyol-sidebar {
  border-right: solid;
  border-width: 1px;
  border-right-color: #334154;
  background: #334155;
  left: 0;
  top: 0;
  width: 60px;
  height: 100vh;
  padding: 10px;
  position: absolute;
  z-index: 999;
  @include transition;

  .optiyol-logo {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .optiol-logo-icon {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }

  .optiol-logo-text {
    margin-top: 10px;
    margin-left: 10px;
    height: 30px;
    opacity: 0;
    visibility: hidden;
    @include transition;
  }

  .optiyol-mobile-menu,
  .optiyol-mobile-menu-times {
    display: none;
  }

  &-nav {
    &-bottom {
      margin-top: auto;
      padding-top: 20px;
    }
    .company-select {
      margin-bottom: 8px;
      height: 38px;
      width: 186px;
      visibility: hidden;
      opacity: 0;
      @include transition;
    }
    .scroll-down {
      width: 186px;
      visibility: hidden;
      opacity: 0;
      color: #fff;
      font-size: 12px;
      margin-bottom: 4px;
      cursor: pointer;

      @include transition;
    }
    .language-select {
      opacity: 0;
      height: 0;
      visibility: hidden;
      @include transition;
    }
    ul {
      height: 100%;
      flex-direction: column;
      display: flex;

      li {
        display: block;
      }

      a {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin: 4px;
        height: 32px;
        width: 34px;
        overflow: hidden;
        color: #fff;
        border-radius: 4px;
        @include transition;

        &.active {
          background-color: #fff;

          span {
            color: #2c4159;
          }

          i {
            color: #2c4159;
          }
        }
      }

      h1 {
        width: 186px;
        height: 18px;
        font-size: 14px;
        margin-left: 9px;
        margin-bottom: 9px;
        font-weight: 300;
        opacity: 0;
        overflow: hidden;
        margin-top: 15px;
        color: #fff;
        @include transition;
      }

      i {
        text-align: center;
        font-size: 20px;
        margin: 7px;
        height: 20px;
        width: 20px;
        @include transition;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        overflow: hidden;
        pointer-events: none;
        width: 0;
        height: 0;
        margin-left: 6px;
        @include transition;
      }
    }
  }

  &.open,
  &:hover {
    width: 212px;

    .optiyol-logo {
      width: 212px;
    }

    .optiol-logo-text {
      opacity: 1;
      visibility: visible;
    }

    .optiyol-sidebar-nav {
      .language-select {
        opacity: 1;
        visibility: visible;
        height: 100%;
        @include transition;
      }
      .company-select {
        transition-delay: 200ms;
        visibility: visible;
        opacity: 1;
      }
      .scroll-down {
        transition-delay: 400ms;
        visibility: visible;
        opacity: 1;
      }
      ul {
        li {
          width: 186px;
        }
        h1 {
          transition-delay: 200ms;
          width: 186px;
          opacity: 1;
        }
        a {
          justify-content: flex-start;
          width: 186px;
        }

        span {
          transition-delay: 200ms;
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          width: auto;
          height: auto;
        }
      }
    }
  }

  .optiyol-mobile-menu-close {
    display: none;
  }
}

.optiyol-main {
  width: calc(100% - 60px);
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 60px;
  z-index: 90;
  position: relative;

  .optiyol-map,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include breakpoint(tablet) {
    left: 0;
    width: 100%;

    .optiyol-map,
    iframe {
      display: none;
    }
  }
}

.optiyol-overlay,
.optiyol-overlay-mobile-menu {
  z-index: 400;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(#0f182a, 0.6);

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  @include transition;

  &.opened {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  &-mobile-menu {
    z-index: 299;
  }
}

.optiyol-operation-card-outside {
  position: absolute;
  z-index: 1;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(#57697f, 0.32);

  &.top-right {
    top: 14px;
    right: 14px;
  }
}

.update-app-version {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 3;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.optiyol-operation-card {
  padding: 24px 16px;
  width: 340px;
  background: #fff;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;

  @include transition;

  &-left {
    display: flex;
    flex-flow: row wrap;
    width: 190px;

    h5 {
      margin-bottom: 16px;
      line-height: 1.2;
      max-height: 38px;
      overflow: hidden;
      width: 100%;
    }

    .graph {
      width: 58px;
      height: 58px;
      display: block;
      border: 4px solid #cdddfe;
      border-radius: 50%;
      position: relative;

      &-text {
        position: absolute;
        left: 50%;
        top: 50%;
        text-align: center;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }

    &-text {
      font-size: 18px;
      font-weight: bold;
      color: #021e64;
      margin-left: 14px;

      span {
        color: #94a3b8;
        font-size: 14px;
      }
    }

    .circular-chart {
      display: block;
      position: absolute;
      top: -6px;
      right: -6px;
      width: 62px;
      height: 62px;
    }

    .circle {
      stroke: #195ef6;
      fill: none;
      stroke-width: 4;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
    }

    @keyframes progress {
      0% {
        stroke-dasharray: 0 100;
      }
    }
  }

  &-right {
    padding-left: 24px;
    width: 116px;

    .title {
      text-align: right;
      font-size: 12px;
      font-weight: 500;
      color: #94a3b8;
      margin-bottom: 12px;
    }

    .orders {
      border-left: 1px solid #e2e8f0;
      margin-left: -32px;
      padding-left: 32px;
    }

    .order-row {
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      &:not(:last-child) {
        margin-bottom: 6px;
      }

      .right {
        font-weight: 700;
      }

      .left {
        position: relative;

        &:before {
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          top: 6px;
          left: -12px;
        }

        &.blue:before {
          background: $color-p;
        }

        &.red:before {
          background: $color-red;
        }

        &.orange:before {
          background: $color-orange;
        }
      }
    }
  }

  &:hover {
    background: #f1f5f9;
  }
}

.optiyol-go-to-detail {
  background: #f8fafc;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;

  i {
    color: #94a3b8;
  }
}

.optiyol-operations-all-list {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 14px;
  z-index: 10;
  overflow-y: auto;
  box-shadow: 0 3px 12px rgba(#57697f, 0.32);
  border-radius: 4px;

  @include transition;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &-header {
    background: #f1f5f9;
    padding: 10px 12px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-weight: 700;
      color: #94a3b8;
    }

    &-search {
      display: flex;
      align-items: center;
      position: absolute;
      right: 12px;
      width: 27px;

      @include transition;

      &-btn {
        width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #e2e8f0;
        border-radius: 100px;
        font-size: 11px;
        color: #94a3b8;
        cursor: pointer;
      }

      form {
        display: none;
      }

      input {
        border: 0;
        color: #94a3b8;
        width: calc(100% - 20px);

        &:focus {
          outline: 0;
        }
      }

      &.opened {
        right: 12px;
        background: #fff;
        border-radius: 100px;
        width: calc(100% - 24px);

        .optiyol-operations-all-list-header-search-btn {
          border: 0;
          cursor: default;
        }

        .optiyol-operations-all-list-header-search-btn-close {
          font-size: 10px;
          color: #94a3b8;
          margin-left: auto;
        }

        form {
          display: block;
        }
      }
    }
  }

  @include breakpoint(tablet) {
    position: relative;
    top: auto;
    left: auto;

    .optiyol-operation-card {
      width: 100%;

      &-left,
      &-right {
        width: 50%;
      }
    }
  }

  &.opened {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.custom-scroll {
  scrollbar-color: #8181a5 #f1f1f1;
  scrollbar-width: auto;
  table {
    padding-right: 20px;
  }
}

.custom-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: transparent;
}

.custom-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-container:hover::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 100px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.optiyol-main-content {
  position: relative;
  height: calc(100vh - 60px);
}

.optiyol-route-list {
  width: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 24px;
  height: calc(100vh - 60px);
  background: $color-white;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  box-shadow: 0 10px 12px rgba(#57697f, 0.32);

  transition: all 0.15s ease-out;

  &.opened {
    width: 340px;
  }
}

.optiyol-route-list-back-btn,
.optiyol-route-list-forward-btn {
  background: $color-p;
  color: $color-white;
  line-height: 1;
  border-radius: 4px;
  height: 37px;
  width: 37px;
  padding: 12px;
  position: absolute;
  top: 10px;
  left: -100vw;
  z-index: 8;

  transition: all 0.15s ease-out;

  &:hover {
    background-color: #0947d7;
    color: $color-white;
  }

  &.opened {
    left: 338px;

    &.most-left {
      left: 678px;
    }
  }
}

.optiyol-route-list-forward-btn {
  top: 50px;
}

.optiyol-route-card {
  background: #fff;
  padding: 20px 16px 24px;
  width: 340px;
  font-size: 12px;
  display: flex;
  flex-flow: row wrap;

  @include transition;

  &:hover,
  &.active {
    background: #f1f5f9;
  }

  &-top-left {
    width: calc(100% - 66px);
    color: #021e64;
    margin-bottom: 16px;
  }

  &-title {
    margin-bottom: 4px;
  }

  &-top-right {
    display: flex;
    width: 66px;
    position: relative;

    .optiyol-route-card-visible-btn,
    .optiyol-route-card-more-menu-btn {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #94a3b8;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;

      @include transition;

      &:hover {
        color: #475569;
      }
    }

    .optiyol-route-card-warning {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #fffbeb;
      border: 1px solid #fef4c7;
      border-radius: 100px;
      padding: 4px 12px;
      font-size: 12px;
      color: #78350f;
      display: flex;
      align-items: center;

      i {
        color: $color-orange;
        margin-right: 6px;
      }
    }
  }

  .optiyol-route-card-percentage-graph {
    width: 100%;
    height: 24px;
    position: relative;
    margin-top: 2px;

    &-line {
      width: 100%;
      height: 5px;
      border-radius: 10px;
      background-color: #94a3b8;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .optiyol-truck {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: $color-white;
      border-radius: 50%;
      color: #94a3b8;
      box-shadow: 0 1px 5px rgba(#57697f, 0.24);
    }
  }

  .optiyol-route-card-percentage-numbers {
    display: flex;
    align-items: center;
    margin-top: 2px;

    .optiyol-no {
      position: relative;
      padding-left: 12px;
      font-size: 12px;
      color: #64748b;

      strong {
        color: #334155;
      }

      &:before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &.green:before {
        background-color: $color-green;
      }

      &.red:before {
        background-color: $color-red;
      }

      &.gray:before {
        background-color: #94a3b8;
      }

      &.red {
        margin: 0 16px 0;
      }
    }
  }
}

.optiyol-route-card-more-menu {
  position: relative;
}

.optiyol-route-card-more-menu-drop-down {
  background: $color-white;
  box-shadow: 3px 0 12px rgba(#57697f, 0.32);
  position: absolute;
  right: 0;
  top: 36px;
  z-index: 100;
  width: 156px;
  padding: 8px 0;
  border-radius: 4px;
  cursor: pointer;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  @include transition;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent $color-white transparent;
    position: absolute;
    top: -8px;
    right: 8px;
  }

  &.opened {
    opacity: 01;
    visibility: visible;
    pointer-events: all;
  }

  i {
    font-size: 13px;
    color: #94a3b8;
    margin-right: 6px;
    width: 14px;
    @include transition;
  }

  span {
    color: #475569;
    @include transition;
  }

  button {
    padding: 6px 14px;
    width: 100%;
    background: transparent;
    outline: 0;
    border: 0;
    text-align: left;
    font-size: 14px;
    cursor: pointer;

    @include transition;

    &:hover {
      background: #f0f5ff;

      i {
        color: $color-p;
      }

      span {
        color: #032b86;
      }
    }
  }
}

.optiyol-route-detail {
  width: 0;
  height: 0;
  overflow: hidden;
  background: #f8fafc;
  position: absolute;
  left: 340px;
  top: 0;
  z-index: 8;
  box-shadow: 3px 3px 6px rgba(#334155, 0.16);
  padding-bottom: 24px;

  transition: all 0.15s ease-out;

  &.opened {
    width: 340px;
    overflow: auto;
    height: calc(100vh - 60px);
  }

  &-top {
    padding: 14px 6px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    a {
      width: 38px;
      height: 38px;
      font-size: 18px;
      color: #94a3b8;

      display: flex;
      align-items: center;
      justify-content: center;

      @include transition;

      &:hover {
        color: #334155;
      }
    }

    &-text {
      text-align: center;
      color: #64748b;

      .bold {
        color: #475569;
      }
    }
  }
}

.optiyol-route-list-top-filter-btn,
.optiyol-route-list-top-filter-heading {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #475569;
  padding: 12px;
  background-color: #f1f5f9;
  border-bottom: 1px solid #e2e8f0;

  .fa-map-marked {
    color: #94a3b8;
    font-size: 15px;
  }

  .fa-angle-down {
    color: #64748b;
    @include transition;
  }

  &.opened {
    .fa-angle-down {
      transform: rotate(-180deg);
    }
  }
}

.optiyol-route-list-top-filters {
  &-row {
    padding: 8px 12px;
    background-color: #f1f5f9;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .desc {
      width: calc(100% - 42px);
    }
  }
}

.optiyol-route-list-top-filter-heading {
  a {
    color: #64748b;
  }
}

.optiyol-route-detail-card {
  padding: 0 8px 0 24px;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 37px 1fr;
  min-height: 70px;

  @include transition;

  .optiyol-route-detail-card-no {
    position: relative;
    width: 37px;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      width: 3px;
      height: 100%;
      background: #cbd5e1;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  .optiyol-route-detail-card-no-circle {
    background: #f8fafc;
    color: #64748b;
    border: 3px solid #cbd5e1;
    width: 37px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: bold;
    z-index: 2;

    &.green {
      background: #10b981;
      border-color: #10b981;
      color: #edfdf5;
    }

    &.blue {
      background: #2967db;
      border-color: #2967db;
      color: #f0f5ff;
    }

    &.red {
      background: #dc2626;
      border-color: #dc2626;
      color: #edfdf5;
    }
  }

  &-text {
    padding: 18px 0;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;

    &-top {
      font-weight: bold;
      font-size: 12px;
      color: #94a3b8;
    }

    &-medium {
      margin: 2px 0 8px;
      color: #1f2a3b;
    }

    &-bottom {
      color: #1f2a3b;
    }

    &-bottom-most {
      margin-top: 4px;
    }

    .bottom-label {
      font-size: 12px;
      font-weight: 500;
      color: #475569;
      padding: 2px 8px;
      border: 1px solid #e2e8f0;
      background-color: #f1f5f9;
      display: inline-block;
      border-radius: 4px;

      i {
        color: #64748b;
      }

      &.orange {
        color: #78350f;
        border-color: #fcd34d;
        background: #fef4c7;
      }

      &.blue {
        color: #0947d7;
        border-color: #cdddfe;
        background-color: #f0f5ff;
      }

      &.red {
        color: #b91c1c;
        border-color: #fecaca;
        background-color: #fef2f2;
      }
    }
  }

  &.first {
    .optiyol-route-detail-card-no {
      &:before {
        top: 50%;
        height: 50%;
      }
    }
  }

  &.last {
    .optiyol-route-detail-card-no {
      &:before {
        top: auto;
        bottom: 50%;
        height: 50%;
      }
    }
  }

  .optiyol-route-detail-card-labels {
    position: absolute;
    top: 18px;
    right: 8px;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
    direction: rtl;

    span {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e2e8f0;
      color: #475569;
      border-radius: 2px;
    }
  }

  &.distance {
    min-height: auto;
    margin: 4px 0;
    background: #f8fafc;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    color: #64748b;
  }

  &.accident {
    background-color: #fef2f2;

    .optiyol-route-detail-card-text-medium,
    .optiyol-route-detail-card-text-bottom {
      color: #dc2626;
    }
  }

  &.current {
    background-color: $color-p;

    .optiyol-route-detail-card-no-circle {
      background-color: #f0f5ff;
      border-color: #f0f5ff;
      color: #032b86;
    }

    .optiyol-route-detail-card-text-top,
    .optiyol-route-detail-card-text-medium,
    .optiyol-route-detail-card-text-bottom {
      color: #f0f5ff;
    }

    .optiyol-route-detail-card-text .bottom-label {
      background-color: #f0f5ff;
      border-color: #0947d7;
      color: #0947d7;
    }

    &:is(a):hover {
      background-color: $color-p;
    }
  }

  &:is(a):hover {
    background-color: $color-white;
  }
}

.optiyol-route-detail-summary {
  background-color: $color-p;
  color: $color-white;
  width: 710px;
  position: absolute;
  left: 50%;
  top: -270px;
  transform: translateX(-50%);
  border-radius: 0 0 8px 8px;

  @include transition;

  &-content {
    display: grid;
    grid-template-columns: 170px 300px 196px;
    padding: 0 24px;

    &-left {
      border-right: 1px solid #5a8cf6;
      padding: 24px 24px 24px 0;
    }

    &-mid {
      padding: 24px;
    }

    &-right {
      padding: 24px 0;
      display: flex;
      flex-flow: column wrap;
      align-items: flex-end;
    }
  }

  table td {
    padding: 4px 0;
  }

  .btn {
    color: #032b86;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    &:hover {
      background-color: #fff;
      color: #032b86;
    }
  }

  &-open-btn {
    width: 230px;
    height: 14px;
    background-color: $color-p;
    color: $color-white;
    text-align: center;
    position: absolute;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0 0 100px 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include transition;

    &:hover {
      color: $color-white;
    }
  }

  &.opened {
    top: -220px;
  }

  &.droped-down {
    top: 0;
    box-shadow: 0 2px 12px rgba(#195ef6, 0.32);

    .optiyol-route-detail-summary-open-btn i {
      transform: rotate(-180deg);
    }
  }

  .graph {
    width: 70px;
    height: 70px;
    display: block;
    border: 4px solid #5a8cf6;
    border-radius: 50%;
    position: relative;

    svg {
      fill: transparent;
    }

    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      width: 100%;
      transform: translate(-50%, -50%);
      font-size: 14px;
    }

    .circular-chart {
      display: block;
      position: absolute;
      top: -6px;
      right: -6px;
      width: 74px;
      height: 74px;
    }

    .circle {
      stroke: #fff;
      fill: none;
      stroke-width: 2;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
    }
  }
}

.optiyol-map-mark {
  position: absolute;
  right: 20%;
  bottom: 40%;
}

.optiyol-map-mark-details {
  position: absolute;
  right: 100px;
  top: 50px;
  z-index: 10;
  background-color: $color-white;
  width: 380px;
  height: 480px;
  padding: 14px;
  box-shadow: 2px 6px 22px rgba(#0054ef, 0.22);
  border-radius: 0 4px 4px 4px;

  &-close-btn {
    position: absolute;
    top: -6px;
    right: -6px;
    background-color: $color-white;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #94a3b8;
  }

  &-left-nav {
    position: absolute;
    left: -44px;
    top: 0;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      background-color: #e2e8f0;
      color: #64748b;
      font-size: 18px;

      @include transition;
    }

    li:nth-child(2) {
      border: 1px solid #cbd5e1;
      border-width: 1px 0;
    }

    li:first-child a {
      border-radius: 4px 0 0 0;
    }

    li:last-child a {
      border-radius: 0 0 0 4px;
    }

    .active a {
      background-color: #fff;
      color: #475569;
    }
  }
}

.optiyol-map-mark-details-inner-content {
  .optiyol-map-mark-details-inner-content-header {
    display: flex;
    flex-flow: row wrap;
    border-bottom: 1px solid #e2e8f0;

    .order-number {
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 18px;
      color: #fff;
      margin-right: 12px;
      border-radius: 50%;
    }

    .text-12 {
      color: #64748b;
      font-weight: 500;
    }

    .order-title {
      flex: 1;
      max-width: 272px;
      max-height: 68px;
    }

    .optiyol-route-card-more-menu-btn {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #94a3b8;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      transition: all 0.3s ease;
    }
  }

  .optiyol-map-mark-details-inner-content-body {
    height: 334px;
    overflow-y: auto;
    padding: 18px 8px 8px 0;
    margin-right: -8px;

    .title {
      font-size: 14px;
      color: #94a3b8;
    }

    .optiyol-bill-sender {
      padding-bottom: 14px;
      margin-bottom: 18px;
      border-bottom: 1px solid #e2e8f0;

      .bold {
        font-size: 12px;
        color: #94a3b8;
      }
    }

    .optiyol-bill-table {
      width: 100%;

      th {
        color: #94a3b8;
      }

      th,
      td {
        padding: 3px 0;
      }

      .name {
        max-width: 80px;
      }

      &.total {
        margin-top: 4px;
        padding-top: 4px;
        border-top: 1px solid #e2e8f0;

        th,
        td {
          color: #334155;
          font-weight: 500;
        }
      }
    }
  }

  .optiyol-map-mark-details-inner-content-footer {
    padding-top: 14px;
    position: sticky;
    bottom: 0;
    margin-top: auto;
  }

  &.cancel {
    .optiyol-map-mark-details-inner-content-header .order-number {
      background: $color-red;
    }
  }
}

.optiyol-gallery-item {
  a {
    position: relative;
    padding-top: 100%;
    width: 80px;
    height: 80px;
    display: inline-block;
    border: 1px solid #cbd5e1;
    border-radius: 2px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.optiyol-photos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 6px;
  }
}

.optiyol-map-mark-details-note {
  display: flex;
  flex-flow: column wrap;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 18px;
  padding-bottom: 18px;
  padding-right: 8px;

  &-top {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    width: 100%;

    &-left {
      width: 90%;
      color: #334155;
      font-weight: bold;
    }

    &-right {
      color: #64748b;
      font-size: 12px;
      font-weight: bold;
    }
  }

  &-bottom {
    color: #475569;
  }
}

.optiyol-add-order {
  background: #f1f5f9;
  position: fixed;
  top: 60px;
  left: -100vw;
  bottom: 0;
  padding: 0 0 70px;
  z-index: 102;
  width: 660px;
  box-shadow: 3px 3px 6px rgba(#334155, 0.16);

  @include transition;

  &.opened {
    left: 60px;
  }

  &-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 24px 14px 32px 14px;
    position: relative;

    &.w-tabs {
      padding-bottom: 60px;
    }
  }

  &-header-tabs {
    position: absolute;
    bottom: -2px;
    z-index: 1;

    li {
      display: inline-block;
    }

    a {
      display: block;
      padding: 10px 24px;
      border-bottom: 4px solid transparent;

      &.active {
        font-weight: bold;
        color: $color-p;
        border-color: $color-p;
      }
    }
  }

  .heading {
    font-size: 16px;
    font-weight: bold;
    color: #64748b;
  }

  &-content {
    height: calc(100vh - 98px - 69px);
    background-color: #f8fafc;
    border-top: 1px solid #cbd5e1;
    overflow-y: auto;
  }

  &-content-inner {
    padding: 32px 14px;
  }
}

.optiyol-footer-buttons {
  background: #ffffff;
  box-shadow: 0 -3px 6px rgba(#3d4b5d, 0.08);
  height: 69px;
  position: absolute;
  left: -100vw;
  bottom: 0;
  right: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 14px;
  width: 660px;
  z-index: 103;

  @include transition;

  &.opened {
    left: 60px;
  }
}

.optiyol-add-order-content-add-order-row,
.optiyol-add-order-content-add-vehicle-row {
  border-bottom: 1px solid #e2e8f0;
  padding: 24px 14px 20px;
}

.optiyol-add-order-content-add-order-row-inner {
  display: grid;
  grid-template-columns: 250px 274px 80px;
  grid-gap: 14px;

  textarea {
    min-height: 96px;
  }
}

.optiyol-add-order-content-add-vehicle-row-inner {
  display: grid;
  grid-template-columns: 538px 80px;
  grid-gap: 14px;

  .col {
    display: flex;
    flex-flow: column wrap;
  }
}

.optiyol-add-order-content-add-order-row-additional {
  height: 0;
  opacity: 0;
  pointer-events: none;

  display: grid;
  grid-template-columns: 250px 274px 80px;
  grid-gap: 14px;
  padding-top: 8px;

  @include transition;

  textarea {
    min-height: 96px;
  }

  &.opened {
    opacity: 1;
    height: auto;
    pointer-events: all;
  }

  .tag-input {
    background-color: #fff;
    border: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    outline: none;
    outline-style: none;
    padding: 6px;
    position: relative;
    width: 100%;
    -webkit-appearance: none;
  }
}

.optiyol-add-order-content-del-order-btn {
  color: #94a3b8;
  margin: 16px 0;
  display: block;
  text-align: center;
  font-size: 20px;
}

.optiyol-planlama-outer {
  background-color: #f8fafc;
}

.optiyol-planlama-header {
  background: #f1f5f9;
  padding: 24px 32px;
  position: relative;
  height: 136px;
  border-bottom: 1px solid #cbd5e1;

  h1 {
    color: #032b86;
    font-size: 18px;
    font-weight: bold;
  }

  .optiyol-planlama-header-tabs {
    position: absolute;
    bottom: -2px;

    li {
      display: inline-block;
    }

    a {
      display: block;
      padding: 10px 24px;
      border-bottom: 3px solid transparent;

      @include transition;

      &:hover,
      &.active {
        border-bottom: 3px solid #063cb2;
      }

      &.active {
        border-bottom: 3px solid #063cb2;
        color: #063cb2;
        font-weight: bold;
      }
    }
  }

  .optiyol-planlama-buttons-right,
  .optiyol-adresler-search-right {
    position: absolute;
    right: 32px;
    bottom: 18px;

    display: flex;
    flex-flow: row wrap;
  }

  .optiyol-adresler-search-right {
    input {
      width: 400px;
      margin-right: 8px;
    }
  }

  .optiyol-data-sync-steps {
    position: absolute;
    left: 32px;
    bottom: 22px;

    .timeline {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li {
      transition: all 200ms ease-in;
    }

    .status {
      width: 120px;
      border-top: 2px dashed #cbd5e1;
      position: relative;
      transition: all 200ms ease-in;
    }

    li:last-child {
      .status {
        padding-right: 0;
        border: 0;
      }
    }

    h4 {
      font-size: 12px;
      color: #64748b;
      margin-top: 16px;

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-color: #cbd5e1;
        border-radius: 25px;
        position: absolute;
        top: -10px;
        left: -2px;
      }

      &:after {
        content: '';
        width: 24px;
        height: 24px;
        background-color: #cbd5e1;
        border-radius: 25px;
        position: absolute;
        border: 2px solid #f1f5f9;
        top: -12px;
        left: 0;
        transition: all 200ms ease-in;
      }
    }

    li.now {
      .status {
        &:before {
          background-color: #34d39a;
          border: none;
          transition: all 200ms ease-in;
        }

        h4 {
          &:after,
          &:before {
            background: #34d39a;
          }

          &:after {
            content: '';
            font-family: 'Font Awesome 5 Free';
            font-size: 9px;
            color: #fff;
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &:last-child {
        &.complete {
          .status {
            padding-right: 0;
            border: 0;
          }
        }
      }
    }

    li.complete {
      .status {
        border-color: #34d39a;

        &:before {
          background-color: #34d39a;
          border: none;
          transition: all 200ms ease-in;
        }

        h4 {
          &:after,
          &:before {
            background: #34d39a;
          }

          &:after {
            content: '\f00c';
            font-family: 'Font Awesome 5 Free';
            font-size: 9px;
            color: #fff;
            font-weight: 900;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &:last-child {
        &.complete {
          .status {
            padding-right: 0;
            border: 0;
          }
        }
      }
    }

    li {
      &:last-child {
        .status h4:after {
          top: -28px;
        }

        .status h4:before {
          top: -26px;
        }
      }
    }
  }

  &.optiyol-settings-header {
    .optiyol-planlama-buttons-right {
      bottom: auto;
      top: 24px;
    }
  }
}

.optiyol-planlama-content {
  padding: 32px;
}

.optiyol-content-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.75em;

  th,
  th a {
    text-align: left;
    color: #94a3b8;
    font-size: 12px;
    font-weight: 500;
    padding: 6px;
  }
  tr {
    background: $color-white;
    box-shadow: 0 1px 2px rgba(#3d4b5d, 0.16);
    @include transition;

    &.headings {
      background: transparent;
      box-shadow: none;
      div {
        &.filter-element {
          height: 40px;
          margin-top: 10px;
          z-index: 1;
          &-open {
            visibility: 1;
            opacity: 1;
            transform: scale(1);
            transition: all 300ms ease;
          }
          &-closed {
            visibility: 0;
            opacity: 0;
            transform: scale(0.5);
            transition: all 300ms ease;
          }
        }
      }

      &:hover {
        box-shadow: none;
      }
    }

    &:hover {
      box-shadow: 0 2px 4px rgba(#3d4b5d, 0.22);
    }
  }

  td,
  th {
    padding: 16px 0;
    vertical-align: middle;
    word-break: keep-all;

    &:first-child {
      padding-left: 14px;
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      padding-right: 14px;
      border-radius: 0 4px 4px 0;

      a {
        color: #94a3b8;
      }
    }
  }

  th:not(:first-child):not(:last-child),
  td:not(:first-child):not(:last-child) {
    padding-right: 6px;
    padding-left: 6px;
  }

  th {
    padding: 4px 0;
    line-height: 1;
  }

  &.address-table {
    td {
      &:first-child {
        min-width: 180px;
      }

      &:last-child {
        min-width: 80px;
      }
    }
  }

  &.planlama-table {
    td {
      &:first-child {
        min-width: 180px;
      }

      &:last-child {
        min-width: 120px;
      }
    }
  }

  &.manage-orders-table {
    td {
      &:first-child {
        width: 40px;
      }

      &:nth-child(2) {
        min-width: 120px;
      }

      &:last-child {
        min-width: 80px;
      }

      &:not(:first-child) {
        min-width: 80px;
      }
    }
  }
}
.optiyol-content-table-results-count {
  display: flex;
  align-items: center;
  color: #64748b;

  select {
    border: 0;
    margin: 0 8px;
  }
}
.divider-horizontal {
  width: 100%;
  height: 1px;
  opacity: 0.7;
}
.divider-vertical {
  height: 100%;
  width: 1px;
  opacity: 0.7;
  background-color: $color-mid-gray;
}
.optiyol-route-order-status {
  display: inline-block;
  padding: 6px 18px;
  background: #8181a51a;
  border-radius: 8px;
  color: #8181a5;
  font-size: 12px;
  font-weight: 500;

  &.rejected,
  &.canceled,
  &.returned,
  &.late,
  &.OFF_DUTY,
  &.denied,
  &.permanentlyDenied &.could_not_be_sent {
    color: #e02020;
    background: #e020201a;
  }

  &.over_completed,
  &.arrived,
  &.early {
    color: #185cf7;
    background: #185cf71a;
  }

  &.accepted,
  &.approved,
  &.completed,
  &.ON_DUTY_IDLE,
  &.on_time,
  &.sent,
  &.granted {
    color: #6dd400;
    background: #6dd4001a;
  }
  &.started,
  &.pending,
  &.ON_DUTY_ROUTE {
    background-color: $color-p;
    color: $color-white;
  }
  &.in_intermediate_warehouse,
  &.partially_completed {
    color: #8a2be2;
    background-color: #892be22f;
  }
  &.pending,
  &.waiting,
  &.ON_DUTY_BREAK,
  &.not_send {
    background-color: $color-orange;
    color: $color-white;
  }
  &.finished {
    background-color: $color-green;
    color: $color-white;
  }
}

.optiyol-pagination {
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(#3d4b5d, 0.16);
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  li {
    color: #8181a5;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &.selected {
      border: 1px solid #ececf2;
      border-radius: 5px;
      a {
        color: #185cf7;
      }
    }
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 12px;
      color: #8181a5;
    }
  }
}

.optiyol-matchscore {
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;

    &.red {
      background: $color-red;
    }

    &.green {
      background: $color-green;
    }

    &.orange {
      background: $color-orange;
    }
  }
}

.optiyol-sign-in-up {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 100vh;

  &-right {
    background: url(../assets/img/optiyol-signinup-bg.png) no-repeat top left;
    align-items: center;
    justify-content: center;
    background-size: cover;
    text-align: center;
    padding: 48px;
    font: 200 48px / 60px $font-body;
    color: #fff;
  }

  &-left {
    padding: 72px 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      max-width: 560px;
    }
  }

  @include breakpoint(tablet) {
    grid-template-columns: repeat(1, 1fr);

    &-left {
      padding: 72px 16px;
      max-width: 100%;
    }

    &-right {
      display: none !important;
    }
  }
}

.optiyol-sign-in-up-form {
  width: 100%;

  img {
    margin-bottom: 26px;
  }

  .optiyol-forgot-pass-link {
    color: #9ca0a4;
  }
}

.optiyol-messages-chat-outer {
  background: #f8fafc;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  right: 0;
  z-index: 90;
  transform: translateX(100vw);
  width: 70px;

  transition: all 0.3s ease-out;

  &.opened {
    transform: translateX(0);
  }

  &.opened-w-chat {
    width: 320px;
    transform: translateX(0);
  }
}

.optiyol-messages-column-short {
  background: #f1f5f9;
  padding: 16px 12px;
  width: 70px;
  display: flex;
  flex-flow: column wrap;
  position: fixed;
  right: 0;
  transform: translateX(100vw);
  top: 0;
  height: calc(100vh - 60px);
  z-index: 90;
  box-shadow: -3px 3px 6px rgba(#334155, 0.16);

  transition: all 0.3s ease-out;

  &.opened {
    transform: translateX(0);
  }

  &.opened-w-chat {
    transform: translateX(-250px);
  }

  a {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 8px;
    background: #64748b;
    color: #e2e8f0;
    font: bold 18px / 28px $font-body;
    border-radius: 50%;

    &.optiyol-messages-add-driver-btn {
      background: #cbd5e1;
      color: #64748b;
      font-size: 12px;
    }

    .has-message-from-driver {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      background: $color-red;
    }
  }
}

.optiyol-messages-column-chat-box {
  width: 250px;
  height: calc(100vh - 60px);
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100vw);
  border-left: 1px solid #e2e8f0;
  display: flex;
  flex-flow: column nowrap;
  z-index: 90;
  opacity: 0;
  transition: all 0.3s ease-out;

  &.opened {
    opacity: 1;
    transform: translateX(0);
  }

  &-content {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 8px;
    display: flex;
    flex-flow: row wrap;
    height: calc(100% - 60px);

    .optiyol-chat-box {
      background: #ffffff;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      padding: 8px;
      display: flex;
      flex-flow: column wrap;
      margin-bottom: 8px;
      width: 90%;

      .optiyol-chat-box-text {
        margin: 0 12px 8px 0;
      }

      .optiyol-chat-box-time {
        color: #94a3b8;
        align-self: flex-end;
      }

      &.optiyol-your-message {
        background: #475569;
        border-color: #334155;
        color: #f1f5f9;
        margin-left: 10%;
        text-align: right;

        .optiyol-chat-box-text {
          margin: 0 0 8px 12px;
        }
      }
    }
  }

  &-send {
    padding: 8px;
    position: fixed;
    bottom: 0;
    background: #f8fafc;

    input {
      padding-right: 42px;
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      outline: 0;
      background: transparent;
      color: $color-p;
      font-size: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;
    }
  }
}

.optiyol-data-sync-content-grid {
  display: grid;
  grid-template-columns: 75% 1fr;
  grid-gap: 24px;

  table {
    th:first-child,
    td:first-child {
      width: 80px;
    }
  }

  .optiyol-planlama-content-entity-title {
    color: #94a3b8;
    font-size: 12px;
    font-weight: 500;
    padding: 11px 0px;
  }
}

.list-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f5f5fa;
  padding: 20px 18px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 18px;
      color: #1c1d21;
      font-weight: bold;
    }
    .count {
      padding: 5px 10px;
      text-align: center;
      font-size: 14px;
      background-color: rgba(244, 190, 94, 0.2);
      color: #f4be5e;
      border: 1px solid #f4be5e;
      border-radius: 10px;
      margin-left: 10px;
    }
    .fas {
      font-size: 18px;
      margin-right: 6px;
    }
    .switch {
      display: flex;
      align-items: center;
      justify-content: center;
      &-item {
        width: 100px;
        height: 40px;
        color: #185cf7;
        background-color: transparent;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        border-radius: 8px;
        cursor: pointer;
        @include transition;
        &-active {
          background-color: #185cf7;
          color: #fff;
        }
      }
    }
    .add-button {
      padding: 10px 20px;
      background-color: #185cf7;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-size: 11px;
      cursor: pointer;
    }
  }
  .list-content {
    flex: 1;
    padding-bottom: 10px;
    margin: 20px 0px 0px 0px;
    background-color: #fff;
    border-radius: 15px;
  }
}

.optiyol-settings {
  display: flex;

  .optiyol-planlama-outer {
    width: calc(100% - 220px);
  }

  .optiyol-settings-sidebar {
    background-color: #fff;
    width: 220px;
    box-shadow: 0 3px 6px rgba(#000000, 0.16);
    z-index: 2;
    padding-top: 32px;

    a {
      display: block;
      padding: 8px 16px;
      font-weight: 500;
      color: #475569;

      @include transition;

      &:hover {
        background-color: #f8fafc;
      }

      &.active {
        background-color: #f8fafc;
        color: #334155;
      }
    }
  }
}

.optiyol-setting-gray-box {
  background-color: #f1f5f9;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 12px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.optiyol-setting-notif-header {
  color: #64748b;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.plan-summary {
  @include breakpoint(tablet) {
    height: 80px !important;
    padding: 0 60px;
  }
  display: flex;
  padding: 0 14px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f3;

  .edit-plan {
    padding: 12px 20px;
    font-weight: 400;
    font-size: 12px;
  }
  .summary-item {
    display: flex;
    align-items: center;
    padding: 3px 7px;
    @include transition;
    &.highlighted {
      z-index: 1002;
      background-color: white;
      border-radius: 10px;
      padding: 10px;
    }
    &.report {
      cursor: pointer;
      span {
        background-color: rgba(94, 129, 244, 0.2);
        i {
          color: $color-p;
        }
      }
    }
    span {
      background-color: rgba(94, 129, 244, 0.1);
      width: 48px;
      height: 48px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      i {
        color: #5e81f4;
        font-size: 19px;
      }
    }
    .label {
      font-size: 13px;
      color: $color-mid-gray;
      font-weight: 300;
    }
    .value {
      font-size: 16px;
      font-weight: 500;
      color: #1c1d21;
    }
  }
}
.route-header {
  background-color: #283547;
  color: #fff;
  .summary-item {
    &.highlighted {
      z-index: 1002;
      background-color: white;
      border-radius: 10px;
      padding: 10px;
      span {
        background-color: #283547;
        i {
          color: #fff;
        }
      }
      .label {
        color: #283547;
      }
      .value {
        color: #283547;
      }
    }
    span {
      background-color: #fff;
    }
    .label {
      color: #fff;
    }
    .value {
      color: #fff;
    }
  }
  img {
    border-radius: 5px;
  }
}
.map-list-switch {
  position: absolute;
  @include transition;
  top: 78px;
  z-index: 3;
  .switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 90px;
    height: 60px;
    .header-switch {
      background-color: #fff;
      color: #5e81f4;
      height: 35px;
      border: 1px solid $color-light-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 110px;
      font-size: 13px;
      font-weight: 300;
      border-radius: 8px;
      left: 0;
      cursor: pointer;
      @include transition;
      &-active {
        font-weight: 500;
        width: 90px;
        border: none;
        background-color: $color-p;
        color: #fff;
        left: 85px;
        z-index: 1;
        span {
          margin-right: 0 !important;
        }
      }
      .fas {
        font-size: 16px;
        margin-right: 4px;
      }
      span {
        margin-right: 20px;
      }
    }
  }
}

.H_zoom {
  border-radius: 3px !important;
  box-shadow: none !important;
  margin-top: 0 !important;
  .H_btn {
    width: 32px;
    height: 32px;
    &:after {
      width: 0 !important;
      height: 0;
      background-color: #fff;
    }
    svg {
      fill: #475569;
      width: 16px;
      height: 16px;
    }
  }
}
.H_ui {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}
.H_ctl {
  margin: 5px;
  .H_active {
    background-color: #fff;
  }
  .H_btn {
    width: 3em;
    height: 3em;
    box-shadow: none;
    border-radius: 3px !important;
    svg {
      fill: #475569;
      width: 16px;
      height: 16px;
    }
  }
  .H_overlay {
    box-shadow: none;
    padding: 0 10px;
    .H_rdo_title {
      background-color: #fff;
      color: #000;
      border-bottom: 1px solid $color-mid-gray;
    }
    .H_btn {
      width: 180px;
      padding: 10px;
      height: 20px;
    }
  }
}

.H_ib_body {
  bottom: 30px;
  box-shadow: none;
  border-radius: 12px;
  box-shadow: 0 0 10px #4c5a92;
  @include transition;
  .H_ib_content {
    margin: 0;
    .H_tib_content {
      margin: 0;
    }
  }

  .H_ib_close {
    right: 17px;
    top: 17px;
    svg.H_icon {
      fill: #1c1d21;
    }
  }
}
.H_ib_tail {
  margin: 0;
  bottom: 25px;
  right: -10px;
  &::before {
    border-top: none;
  }
}
.H_btn.H_active {
  font-weight: bold;
}
.map {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 1;
  @include transition;
  &.border-radius {
    canvas {
      border-radius: 10px;
    }
  }
  &-fullscreen {
    z-index: 1002;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }
  .marker-hovered {
    position: absolute;
    top: 14px;
    left: 24px;
    background-color: #fff;
    max-width: 430px;
    font-weight: 300;
    padding: 6px 20px;
    z-index: 3;
    border-radius: 5px;
  }
  .full-screen {
    position: absolute;
    right: 21px;
    cursor: pointer;
    bottom: 157px;
    background-color: #fff;
    color: #0f182a;
    z-index: 3;
    padding: 3px 7px;
    font-size: 16px;
    border-radius: 5px;
  }
  .extra-settings {
    position: absolute;
    right: 21px;
    cursor: pointer;
    bottom: 192px;
    background-color: #fff;
    color: #0f182a;
    z-index: 3;
    padding: 3px 7px;
    font-size: 16px;
    border-radius: 5px;
    top: unset;
  }
  .route-loading-info {
    position: absolute;
    right: 21px;
    cursor: pointer;
    bottom: 227px;
    background-color: #fff;
    color: #0f182a;
    z-index: 3;
    padding: 3px 7px;
    font-size: 16px;
    border-radius: 5px;
  }
  .tilt-control {
    position: absolute;
    bottom: 122px;
    right: 21px;
    cursor: pointer;
    background-color: #fff;
    color: #0f182a;
    z-index: 3;
    padding: 3px 7px;
    font-size: 16px;
    border-radius: 5px;
    .tilt-slider {
      position: absolute;
      right: 51px;
      cursor: pointer;
      bottom: 0px;
      background-color: #fff;
      padding: 3px 17px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .distance-calculator {
    position: absolute;
    bottom: 20px;
    z-index: 3;
    right: 60px;
    padding: 3px 7px;
    border-radius: 5px;
    background-color: #fff;

    &-info {
      position: absolute;
      background-color: #fff;
      bottom: 0px;
      right: 40px;
      width: 370px;
      padding: 7px;
      text-align: center;
      border-radius: 5px;
    }
  }
}

.top-bar {
  height: 76px;
  padding: 20px 30px;
  align-items: center;
  flex-direction: row;
  background-color: #f5f5fa;
  display: flex;
  border-bottom: solid 1px;
  border-color: #f0f0f3;
}

.hide-scroll-h::-webkit-scrollbar {
  height: 6px;
  width: 0;
}

.hide-scroll-h {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.greyout {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
}

.manuel-select-info {
  z-index: 3;
  position: absolute;
  top: 14px;
  left: 24px;
  padding: 5px 14px;
  background-color: white;
  border-radius: 5px;
  color: $color-orange;
  opacity: 0;
  visibility: hidden;
  @include transition;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.plan-change-info {
  z-index: 2;
  position: absolute;
  cursor: pointer;
  top: 12px;
  left: 24px;
  padding: 7px 14px;
  background-color: white;
  color: #475569;
  font-weight: 300;
  font-size: 13px;
  border-radius: 5px;
}

.change-sequence {
  position: absolute;
  top: 50px;
  right: 29px;
  z-index: 1;

  .dropdown {
    min-width: 155px;
    position: relative;
    padding: 7px 0 7px 14px;
    font-size: 13px;
    color: #475569;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
    font-weight: 300;
    i {
      width: 20px;
    }
    .toggler {
      position: absolute;
      top: 6px;
      right: 1px;
      font-size: 12px;
      color: #475569;
      cursor: pointer;
      @include transition;
      &-open {
        transform: rotate(180deg) translateX(6px);
      }
    }
  }
  .change-sequence-actions {
    background-color: white;
    border-radius: 5px;
    margin-top: 3px;
    display: none;
    &.open {
      display: block;
    }
    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .action {
      display: flex;
      align-items: center;
      padding: 7px 10px 7px 14px;
      font-size: 13px;
      min-width: 155px;
      color: #475569;
      cursor: pointer;
      font-weight: 300;

      i {
        width: 20px;
      }
      &-selected {
        &.manuel {
          background-color: $color-orange;
          color: #fff;
          font-weight: bold;
        }
        font-weight: 500;
      }
    }
    .divider {
      background-color: #e2e8f0;
      width: 100%;
      height: 1px;
    }
  }
}

.change-sequence-infobar {
  position: absolute;
  bottom: 25px;
  left: 20px;
  z-index: 3;
  background-color: #fff;
  border-radius: 12px;
  width: 90%;
  height: 75px;
  display: flex;
  align-items: center;
  @include transition;

  &-open {
    border-top-left-radius: 0;
  }
  .left {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    position: relative;
    @include transition;
    .selected-orders-toggler {
      position: absolute;
      top: -18px;
      left: 50%;
      font-size: 12px;
      color: #1c1d2188;
      cursor: pointer;
      @include transition;
      &-open {
        transform: rotate(180deg);
      }
    }

    .label {
      margin-left: 7px;
      margin-right: 15px;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      align-items: center;
      position: relative;
      .pickup-count {
        left: -5px;
        top: 40px;
        font-size: 12px;
      }
      .count {
        width: 30px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        background-color: rgba(94, 129, 244, 0.1);
        color: #5e81f4;
        border-radius: 12px;
        margin-right: 10px;
      }
    }
    .info {
      display: flex;
      justify-content: space-between;
      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-width: 100px;
        .value {
          font-size: 14px;
          color: #1c1d21;
        }
        .label {
          font-size: 12px;
          opacity: 0.5;
        }
        .fas {
          color: $color-mid-gray;
          font-size: 17px;
          margin-right: 5px;
        }
      }
    }
  }
  .right {
    flex: 1;
    height: 100%;
    background-color: rgba(244, 190, 94, 0.2);
    border-radius: 12px;
    padding: 10px;
    color: #f4be5e;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #f4be5e;
    &-sequence {
      flex: 1;
      height: 100%;
      display: flex;
      padding: 0px 10px;
      min-width: 155px;
      justify-content: center;
      align-items: center;
      background-color: #f6f6f6;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      div {
        margin: 0 10px;
      }
    }
  }
}

.change-sequence-complete {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 3;
  bottom: 120px;
  display: flex;
  align-items: center;
  @include transition;
  .action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: #185cf7;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    &.remove-selections {
      background-color: #fff;
      color: #f71818;
    }
    &.reselect {
      background-color: #fff;
      color: #185cf7;
    }

    .fas {
      font-size: 20px;
      margin-right: 10px;
    }
  }
}

.order-label-header {
  width: 100%;
  border-bottom: 1px solid $color-mid-gray;
  color: $color-mid-gray;
}

.selected-orders {
  position: absolute;
  bottom: 100px;
  left: 20px;
  z-index: 3;
  background-color: #fff;
  padding-right: 20px;
  height: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @include transition;
  &-open {
    height: 300px;
  }
  ul {
    overflow: hidden;
    overflow-y: scroll;
    height: 0;
    @include transition;
    &.open {
      height: 300px;
    }
    li {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border-bottom: 1px solid #e2e8f0;
      .marker {
        margin-right: 10px;
      }
      .item {
        color: #1c1d21;
        font-size: 13px;
        padding: 5px 0;
        margin-right: 10px;
        .fas {
          margin-right: 10px;
          font-size: 17px;
          &.red {
            color: #e02020;
            font-size: 20px;
          }
          &.black {
            color: #1c1d21;
            font-size: 20px;
          }
        }
      }
      .fas {
        font-size: 17px;
        &.red {
          color: #e02020;
        }
        &.black {
          color: #1c1d21;
        }
      }
      .address {
        flex: 1;
      }
    }
  }
}
.search-bar-container-route-list-view {
  display: flex;
  border: 1px solid #ececf2;
  font-size: 12px;
  height: 20px;
  border-radius: 6px;
  padding: 20px 5px 20px 0px;
  width: 310px;
  align-items: center;
  input {
    border-radius: 6px;
    height: 20px;
    font-size: 12px;
    border: 1px solid transparent;
    padding: 19px 19px 19px 6px;
    width: 300px;
    outline: none;
  }
  .fas {
    color: #8181a5;
    padding: 12px 0px 12px 12px;
    font-size: 16px;
  }
}
.chrome-picker {
  box-shadow: none !important;
  width: 200px !important;
}
.absolute-ver-hor-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.absolute-hor-center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: max-content;
}
.absolute-ver-center {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.marker-location-box {
  bottom: 50%;
  top: unset;
  transform: translate(-50%, -50px);
  z-index: 4;
  border: 1px solid $color-light-gray;
  background-color: #fff;
  min-width: 200px;
  max-width: 300px;
  padding: 10px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    line-height: 18px;
    margin-right: 8px;
  }
}
.fix-location-marker {
  transform: translate(-50%, -41px);
}
.rc-time-picker {
  width: 100%;
}

.react-select-virtualized {
  .fast-option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-mid-gray;
    &-selected {
      color: $color-white;
    }
  }
}
.react-select {
  &-debounce {
    &__control {
      width: 550px;
      .search-first-address {
        cursor: pointer;
        padding: 0 7px;
        margin-right: 5px;
        color: $color-s;
      }
    }
    &__indicator-separator {
      margin: 0 5px;
    }
    &__option {
      background-color: #fff !important;
      &--is-focused {
        background-color: $color-light-gray !important;
      }
      &--is-selected {
        background-color: $color-mid-gray !important;
      }
    }
  }
}
.debounce-search {
  .debounce-input {
    border: 1px solid #f0f0f3;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 10px 17px;
    font-size: 12px;
    color: #1c1d21;
  }
  ul {
    width: 340px;
    background-color: #f9f9f9;
    border-radius: 8px;
    margin-top: 5px;
    li {
      padding: 5px 17px;
      font-size: 12px;
      position: relative;
      cursor: pointer;
      &:last-child {
        &::after {
          height: 0;
        }
      }
      &:hover {
        background-color: #1c1d2109;
      }
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $color-mid-gray;
        bottom: 0;
        left: 0;
      }
    }
  }
}
.planning-container {
  background-color: #f5f5fa;
  display: flex;
  flex-direction: column;
  .inner {
    background-color: white;
    margin: 15px 18px 0px 18px;
    border-radius: 15px;
    .actions-header {
      padding: 5px 20px 0px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-set {
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          width: 32px;
          height: 32px;
          cursor: pointer;
          margin-right: 6px;
          background-color: #8181a521;
          color: #8181a5;
          border-radius: 8px;
          &:hover {
            background-color: #8e8ea83f;
          }
          &.basic {
            font-size: 16px;
            border-radius: unset;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.planning-data-type-tab {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: scroll;
  margin: 0 auto;
  width: calc(100vw - 500px);
  div {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #ececf2;
    white-space: nowrap;
    background-color: white;
    color: #185cf7;
    padding: 10px 18px;
    font-size: 12px;
    i {
      margin-left: 5px;
      margin-right: 5px;
    }
    &.orange {
      background-color: #fff8eb;
      color: #f4be5e;
      .count {
        background-color: #f4be5e;
        color: white;
      }
      &.active {
        background-color: #f4be5e;
        color: white;
        .count {
          background-color: white;
          color: #f4be5e;
        }
      }
    }

    .count {
      margin-left: 10px;
      padding: 1px 6px;
      text-align: center;
      border-radius: 10px !important;
      color: #185cf7;
      border: 1px solid #ececf2;
    }
    &.active {
      background-color: #185cf7;
      color: white;
      .count {
        background-color: white;
      }
    }
  }
  div:only-child {
    border-radius: 8px !important;
  }
  div:first-child {
    border-radius: 8px 0px 0px 8px;
  }
  div:last-child {
    border-radius: 0px 8px 8px 0px;
  }
}
.planning-overview {
  padding: 10px 20px;
  .title {
    font-weight: bold;
    font-size: 16px;
  }
  .subtitle {
    font-size: 14px;
    color: #8181a5;
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: -8px;
    .fas {
      height: auto;
      width: auto;
      margin-right: 10px !important;
      background-color: transparent;
      margin-right: 5px;
      color: #8181a5;
    }
  }
  .record-count {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 20px;
    background-color: rgba(245, 245, 250, 0.4);
    border-radius: 10px;
  }
}
.progress-bar {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background-color: $color-light-gray;
  div {
    height: 5px;
    border-radius: 5px;
  }
}

.standart-icon {
  color: unset !important;
  background-color: unset !important;
  height: unset !important;
  width: unset !important;
}

.ellipsis-second-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.history-box {
  background-color: #fff;
  .list {
    max-height: 70vh;
    overflow: scroll;
    padding-right: 15px;
  }
  .item {
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-light-gray;
    .count {
      font-size: 14px;
      background-color: $color-orange;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      text-align: center;
      line-height: 25px;
      color: $color-white;
      margin-right: 20px;
    }
    &.with-hover {
      padding: 10px 20px;
      &:hover {
        cursor: pointer;
        border-radius: 8px;
        background-color: $color-light-gray;
      }
    }
  }
}
.plan-modification-footer {
  width: 100%;
  height: 60px;
  border-top: 1px solid $color-light-gray;
  border-left: 1px solid $color-light-gray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  position: relative;

  .count {
    padding: 3px 10px;
    border-radius: 10px;
    background-color: #f7b500;
    color: #fff;
    font-size: 14px;
  }
}

.selected-route-count {
  padding: 2px 6px;
  background-color: rgba(94, 129, 244, 0.1);
  color: #185cf7;
  margin-right: 5px;
  border-radius: 8px;
}

.route-seq-number-container {
  margin-top: -9px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block !important;
}
.text-overflow-ellipsis-inline {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block !important;
}

.max-width-210-px {
  max-width: 210px;
}

.column-management-modal {
  .table-column-management {
    display: flex;
    flex-direction: column;
    flex: 1;
    .list-header {
      font-size: 15px;
      margin: 15px 0 7px;
      padding: 0 8px;
    }
    .table-column-droppable-list {
      flex: 1;
      overflow-y: scroll;
      padding-right: 10px;
      margin-right: 5px;
      .item {
        padding: 13px 20px;
        border: 1px solid #ececf2;
        border-radius: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0;
      }
    }
  }
}
.table-like-list {
  flex: 1;
  overflow-y: scroll;
  padding-right: 10px;
  margin-right: 5px;
  .item {
    padding: 13px 20px;
    border: 1px solid #ececf2;
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin: 8px 0;
  }
}
.no-border {
  border: none !important;
}
.table-header-icon {
  padding: 10px;
  background-color: #f2f2f6;
  color: #8181a5;
  margin-left: 8px;
  border-radius: 8px;
  &:hover {
    background-color: #d4d4db;
  }
}

.box-icon {
  padding: 10px;
  background-color: #f2f2f6;
  color: $color-mid-gray;
  margin-left: 10px;
  border-radius: 8px;
}

.__react_component_tooltip {
  z-index: 1001 !important;
}

.lat-lon-input {
  min-height: 30px;
  min-width: 240px;
  padding: 6px;
  border: 1px solid #d0d0d3;
}

.lat-lon-input-go-btn {
  min-height: 30px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
  border: 1px solid #d0d0d3;
  background-color: #3474ff;
  &:hover {
    background-color: #145eff;
  }
  i {
    font-size: 20px;
    color: white;
  }
}

.bor-t-t {
  border-top: transparent;
}
.bor-b-t {
  border-bottom: transparent;
}
.bor-l-t {
  border-left: transparent;
}
.bor-r-t {
  border-right: transparent;
}

.route-animation-box {
  z-index: 41242412;
  position: absolute;
  top: 0;
  right: 0;
  flex: 1;
  height: 100vh;
  width: 400px;
  .header {
    background-color: #283547;
    color: #fff;
    padding: 0px 20px;
    height: 84px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .order-info {
    top: 10px;
    z-index: 4;
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 7px;
    color: #475569;
    white-space: nowrap;
    max-width: 600px;
    overflow: auto;
    text-overflow: ellipsis;
  }
  .footer {
    height: 74px;
    padding: 20px 20px;
    background-color: #fff;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .previous {
      position: absolute;
      cursor: pointer;
      left: 43%;
      top: 0px;
    }
    .next {
      position: absolute;
      cursor: pointer;
      right: 43%;
      top: 0px;
    }
    .play-controls {
      svg {
        .play-path {
          transform: scale(0.7) translate(5px, 5px);
        }
        .pause-path {
          transform: scale(0.3) translate(28px, 28px);
        }
        &:hover {
          circle {
            fill: #0947d7;
          }
        }
      }
      display: flex;
      align-items: flex-end;
      margin: 0;
      position: absolute;
      top: -30px;
      cursor: pointer;
      z-index: 3;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
    }
    .line {
      width: 90%;
      background-color: #eef2fd;
      height: 8px;
      border-radius: 4px;
      position: relative;
      .order-parts {
        display: flex;
        position: absolute;
        width: 100%;
        .part {
          @include transition;
          cursor: pointer;
          &:hover {
            background-color: #7397e6;
          }
          .part-divider {
            width: 5px;
            height: 6px;
            border-radius: 50%;
            background-color: #fff;
          }
        }
      }
      .blue-line {
        background-color: #195ef6;
        height: 8px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        @include transition;
        width: 0px;
      }
      .current {
        background-color: #195ef6;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        @include transition;
        left: 0px;
      }
    }
  }
}
.tracking {
  .list-view {
    width: 100%;
    &-box {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      width: 100%;
    }
    &-footer {
      padding: 0px 20px;
      position: relative;
      height: 74px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-top: 1px solid #ececf2;
      .fas {
        padding: 10px;
        background-color: #f2f2f6;
        color: $color-mid-gray;
        margin-left: 10px;
        border-radius: 8px;
      }
    }
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      .tab {
        padding: 6px 25px;
        color: $color-mid-gray;
        border-radius: 8px;
        font-weight: 500;
        cursor: pointer;
        &-active {
          border: 1px solid #ececf2;
          color: #1c1d21;
        }
      }
      .count {
        font-size: 14px;
        color: #5e81f4;
        padding: 3px 12px;
        height: 27px;
        margin-left: 10px;
        background-color: rgba(94, 129, 244, 0.1);
        border-radius: 8px;
      }
      .text {
        color: #1c1d21;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .search-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      width: 100%;
    }
  }
}
.scenario-select-search {
  width: 100%;
  border: solid transparent;
  &:focus-visible {
    outline: transparent;
  }
}
.polygon-draw-info {
  z-index: 3;
  background-color: #fff;
  font-size: 12px;
  bottom: 5px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  top: unset;
  border-radius: 8px;
  padding: 0 10px 0 20px;
  &.avoid-area-draw-info {
    color: white;
    box-shadow: unset;
    background-color: transparent;
    padding: 0px;
    bottom: 50%;
    right: 0px;
  }
  .header {
    color: $color-s;
    text-align: center;
    margin-right: 10px;
  }
  .divider-vertical {
    height: 18px;
  }
  .action {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 4px 5px;
    border-radius: 8px;
    color: $color-s;
    margin: 6px 7px 5px 7px;
    position: relative;
    display: flex;
    &.avoid-area-action {
      color: white;
    }
    .fas {
      margin-right: 7px;
    }
  }
}
.primay-color-with-hover {
  color: #185cf7;
  &:hover {
    color: #0044e4;
  }
}
.create-new-account-btn {
  font-weight: bold;
  background-color: rgba(94, 129, 244, 0.1);
  color: #185cf7;
  border-radius: 10px;
  &:hover {
    background-color: rgba(94, 129, 244, 0.2);
  }
}
.vehicle-status-plan {
  padding: 1px 6px;
  border: 1px solid black;
  font-size: 13px;
  border-radius: 6px;

  &.purple {
    border-color: #8a2be2;
    color: #8a2be2;
    background-color: #892be22f;
  }

  &.blue {
    border-color: #195ef6;
    color: #195ef6;
    background-color: #195ef62f;
  }

  &.green {
    border-color: #10b981;
    color: #10b981;
    background-color: #10b9812f;
  }
}

.polygon-description {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  bottom: 50px;
  @include transition;
}

.primay-btn-color-with-hover {
  background-color: #185cf7;
  color: white;
  &:hover {
    background-color: #0044e4;
  }
}

.barcode-sample {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  border: 1px solid;
  border-radius: 10px;
}

.circle-seq-num {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  border-radius: 50%;
  min-width: 24px;
  height: 24px;
  margin-right: 4px;
  &.-arrived {
    border-color: #f7b500;
    background-color: #f7b50055;
    color: #f7b500;
  }
  &.-complete {
    border-color: #6dd400;
    color: #6dd400;
    background-color: #6dd40055;
  }
  &.-canceled {
    border-color: #e02020;
    color: #e02020;
  }
  &.-missing {
    background-color: #fefcf7;
    border-color: #f5d680;
  }
  &.-warning {
    background-color: #fef6ff;
    border-color: #ecb9b9;
  }
  &.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }
  &.x-small {
    width: 13px;
    min-width: 13px;
    height: 13px;
  }
}

.complete-order-status-actions {
  padding: 5px;
  height: 84px;
  border: 1px solid #ececf2;
  border-radius: 6px;
  background-color: white;
  color: #8181a5;
  cursor: pointer;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &.active {
    background-color: rgba(109, 212, 0, 0.03);
    color: #6dd400;
  }
  input[type='file'] {
    display: none;
  }
  .fas {
    font-size: 30px;
  }
  .text {
    font-size: 14px;
    text-align: center;
  }
}

.fix-geocode-warning-top {
  .icon-set {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-right: 6px;
      background-color: #8181a521;
      color: #8181a5;
      border-radius: 8px;
      &:hover {
        background-color: #8e8ea83f;
      }
    }
  }
}
.geocode-handle-method-style {
  padding: 2px;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 4px;
  color: black;
  &.exclude {
    border-color: #e02020;
    background-color: #e0202010;
    color: #e02020;
  }
  &.location-accepted {
    background-color: #fef6ff;
    border-color: #ea72ff;
    color: #ea72ff;
  }
  &.location-fixed {
    border-color: #10b981;
    background-color: #10b98110;
    color: #10b981;
  }
}
.geocode-score-circle {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  &.red {
    background-color: #e02020;
    color: #e02020;
  }
  &.yellow {
    background-color: #ffcb1e;
    color: #ffcb1e;
  }
  &.green {
    background-color: #10b981;
    color: #10b981;
  }
}

.geocode-warning-container {
  padding: 8px;
  border: 1px solid black;
  border-radius: 8px;
  &.error {
    border-color: #e02020;
    background-color: #e0202005;
  }
  &.warn {
    border-color: #ffcb1e;
    background-color: #ffcb1e05;
    .more-action-button-bulk {
      border-radius: 6px;
      border: 1px solid #ffcb1e;

      padding: 0px 5px;
      color: #8181a5;
    }
  }
  .info-card {
    width: 100%;
    border-radius: 6px;
    border: 1px solid black;
    padding: 4px;
    margin-top: 4px;
    &.error {
      border-color: #e02020;
      background-color: #e0202015;
    }
    &.warn {
      border-color: #ffcb1e;
      background-color: #ffcb1e15;
      .more-action-button {
        border-radius: 6px;
        border: 1px solid #ffcb1e;

        padding: 0px 5px;
        color: #8181a5;
      }
      &.accept {
        .more-action-button {
          border: 1px solid #ea72ff;
        }
        border-color: #ea72ff;
        background-color: #fef6ff;
      }
      &.fixed {
        .more-action-button {
          border: 1px solid #10b981;
        }
        border-color: #10b981;
        background-color: #10b98110;
      }
    }
  }
}

.divider {
  background-color: #e2e8f0;
  width: 100%;
  height: 1px;
}
.bulk-report-export {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin-top: 1px;
  padding: 0 20px;
  border-top: 1px solid $color-light-gray;
  &.height-70 {
    height: 70px;
  }
  .export-buttons {
    display: flex;
    .btn {
      margin: 0 10px;
    }
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.actual-route-length-box {
  background-color: #fff;
  border-radius: 10px;
  z-index: 4;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: max-content;
  bottom: 20px;
  justify-content: space-between;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  .route-length-item {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 14px 10px 20px;
    @include transition;
    &:hover {
      background-color: #adadc633;
    }
  }
  .route-length-item-no-hover {
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 14px 10px 20px;
  }
}

.beta-badge {
  font-size: 10px;
  background-color: $color-orange;
  color: #fff;
  padding: 4px 6px;
  border-radius: 0 5px 0 5px;
  position: absolute;
  right: -40px;
  top: -20px;
}

.new-badge {
  font-size: 10px;
  background-color: $color-p;
  color: #fff;
  padding: 4px 6px;
  border-radius: 5px;
  position: absolute;
  right: -40px;
  top: -10px;
  animation: shake 0.5s infinite;
  cursor: default;
  /* When the animation is finished, start again */
  &.ten-times {
    animation-iteration-count: 15;
  }
}
.info-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid $color-light-gray;
  border-radius: 10px;
  width: 23%;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.add-order-button {
  position: absolute;
  top: 12px;
  right: 29px;
  z-index: 3;
  padding: 7px 14px;
  font-size: 13px;
  color: #475569;
  cursor: pointer;
  background-color: white;
  border-radius: 5px;
  font-weight: 300;
  min-width: 155px;
  @include transition;
  transition-delay: 300ms;
  i {
    margin-right: 5px;
  }
  &.list-view {
    border-radius: 8px;
    top: 12px;
    right: 29px;
  }
}
.add-button {
  padding: 10px 20px;
  background-color: #185cf7;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 11px;
  cursor: pointer;
  &.red {
    background-color: #f04444;
    &:hover {
      background-color: #cf3b3b;
    }
  }
}

.hidden {
  visibility: hidden;
}

.service-worker-update {
  position: fixed;
  right: 10px;
  bottom: 10px;
  max-width: 400px;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  z-index: 10;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 15px;
  svg {
    width: 300px;
    height: 207px;
  }
}

.two-fa-table-cell-style {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .fas {
    padding: 4px;
    border-radius: 50%;
    color: white;
  }
  .fa-check {
    background-color: #10b981;
  }
  .fa-times {
    background-color: #dc2626;
  }
}
.two-fa-title-username {
  margin-left: 10px;
  width: 260px;
  color: #8181a5;
  margin-top: -10px;
}

.over-flow-scroll {
  overflow: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
}
.team-member-list-item {
  background-color: #f5f5fa;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 6px;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 95%;
  .more-actions-container {
    position: absolute;
    right: -20px;
    top: calc(50% - 20px);
    .more-action-box {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background-color: #d4dfff;
      &:hover {
        background-color: #aac0ff;
      }
      .more-action-box-icon {
        font-size: 20px;
        color: #185cf7;
      }
    }
  }
  .info-box {
    display: flex;
    align-items: center;
    flex-direction: row;
    .box {
      &.x2 {
        width: 30%;
      }
      &.x1 {
        width: 45%;
      }
      padding: 6px;
      margin-right: 20px;
      .box-title {
        color: #8181a5;
        font-weight: 300;
      }
      .box-value {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        .colored-box {
          padding: 3px;
          border-radius: 6px;
          border: 1px solid;
          &.active {
            color: #6dd400;
            border-color: #6dd400;
            background-color: #6dd40012;
          }
          &.passive {
            color: #e02020;
            border-color: #e02020;
            background-color: #e0202012;
          }
        }
      }
    }
  }
}

.predefined-avoid-areas-show-btn {
  height: 32px !important;
  width: 32px !important;
  margin: 0px !important;
  line-height: 32px !important;
  &.active {
    background-color: #195ef6 !important;
    color: white !important;
  }
  &.white {
    height: 34px !important;
    width: 34px !important;
    border-radius: 8px;
    background-color: white;
    color: #8181a5;
  }
}
.active-passive-avoid-area-badge {
  padding: 4px;
  border: 1px solid;
  border-radius: 6px;
  &.active {
    background-color: #6dd40012;
    color: #6dd400;
    border-color: #6dd400;
  }
  &.passive {
    background-color: #e0202012;
    color: #e02020;
    border-color: #e02020;
  }
  &.blue {
    padding: 6px;
    cursor: pointer;
    background-color: #195ef6;
    color: white;
    &:hover {
      background-color: #0050fc;
    }
  }
}
.avoid-area-footer {
  height: 50px;
  .content-of-footer {
    width: 100%;
    height: 100%;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.blue {
  color: #0947d7;
  border-color: #cdddfe;
  background-color: #f0f5ff;
}
.route-report-status-badge {
  font-size: 12px;
  padding: 6px;
  border-radius: 8px;
  color: white;
  &.completed {
    background-color: #10b981;
  }
  &.on-delivery {
    background-color: #195ef6;
  }
  &.waiting {
    background-color: #f59e26;
  }
}

.validation-error-list-container {
  padding: 8px;
  border: 1px solid black;
  border-radius: 8px;
  border-color: #f59e26;
  background-color: #f59e2605;
  .info-card {
    width: 100%;
    border-radius: 6px;
    border: 1px solid black;
    padding: 6px;
    margin-top: 4px;
    border-color: #f59e26;
    background-color: #f59e2615;
    &.solved {
      border-color: #10b981;
      background-color: #10b98115;
    }
    &.ignored {
      border-color: #8a2be2;
      background-color: #8a2be215;
    }
    .error-item {
      width: 75%;
    }
  }
}

.visibility {
  visibility: visible;
  opacity: 1;
  overflow: hidden;
  @include transition(all, 0.7s);

  &.false {
    visibility: hidden;
    opacity: 0;
  }
}

.stepProgressBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin: 0 auto;
  margin-top: 10px;
  .step {
    text-align: center;
    position: relative;

    .bullet {
      border: 1px solid $color-green;
      height: 16px;
      width: 16px;
      border-radius: 100%;
      display: inline-block;
      position: relative;
      line-height: 16px;
      @include transition;
      &.current {
        border: 3px solid $color-p;
        height: 20px;
        width: 20px;
      }
      &.completed {
        background-color: $color-green;
        &::after {
          content: '';
          position: absolute;
          right: -165px;
          bottom: 7px;
          height: 1px;
          width: 165px;
          background-color: $color-green;
        }
      }
    }
  }
}

/* borderRadius: '8px',
                  borderColor: '#F4BE5E',
                  color: filterValues.is_sent_for_routing === 'False' ? 'white' : '#a57b33',
                  backgroundColor:
                    filterValues.is_sent_for_routing === 'False' ? '#F4BE5E' : '#F4BE5E21', */

.service-type-select-btn {
  margin: 8px;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  border-color: $color-p;
  &.passive {
    color: $color-p;
    background-color: #195ef621;
  }
  &.active {
    color: white;
    background-color: #195ef6;
  }
}
.color-box {
  width: 9px;
  height: 9px;
  border: 3px solid black;
  background-color: white;
  &.red {
    border-color: $color-red;
  }
  &.blue {
    border-color: $color-p;
  }
}
.tracking-selected-orders {
  width: 100%;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .btn-container {
    display: flex;
    align-items: center;
  }
  .label {
    margin-left: 7px;
    margin-right: 15px;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    .count {
      width: 30px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      font-size: 14px;
      background-color: rgba(94, 129, 244, 0.1);
      color: #5e81f4;
      border-radius: 12px;
      margin-right: 10px;
    }
  }
}

.consensus-modal-box {
  height: 31px;
  border: 1px #b3b3b3 solid;
  border-radius: 6px;
  padding: 4px 8px;
  width: 155px;
}

.location-history {
  margin-top: 6px;
  padding: 4px 6px;
  border-radius: 8px;
  border: 1px solid;
  &.create {
    border-color: #10b981;
    background-color: #10b98114;
  }
  &.lastUpdate {
    border-color: #f7b500;
    background-color: #f7b50014;
  }
  &.verify {
    border-color: #185cf7;
    background-color: #185cf714;
  }
  &.approve {
    border-color: #8a2be2;
    background-color: #8a2be214;
  }
  .action {
    &.create {
      color: #10b981;
    }
    &.lastUpdate {
      color: #f7b500;
    }
    &.verify {
      color: #185cf7;
    }
    &.approve {
      color: #8a2be2;
    }
  }
}
.overflow-ellipsis-200-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.label-view-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .label-element {
    margin-bottom: 6px;
    background-color: #eee;
    display: flex;
    align-items: center;
    margin-right: 6px;
  }
  .label-content {
    height: 30px;
    padding: 4px 4px 4px 8px;
  }
  .delete-label-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 25px;
    color: #000;
    background-color: #eee;
    &:hover {
      color: #ff6d6d;
      background-color: #ffc7c7;
    }
  }
}

.add-label-group-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .column {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding-right: 24px;
    margin: 8px 0;
    label {
      color: $color-mid-gray;
      font-weight: 500;
    }
  }
}

.datePickerRangeContainer {
  width: 100%;
  position: relative;
  display: inline-block;
  z-index: 100;
}

.courier-tracking {
  .list-view {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    z-index: 2;
    top: 0;
    left: 0;
  }
  .line-item {
    position: relative;
    margin: 10px 0px 10px 20px;
    &::before {
      content: '';
      position: absolute;
      left: -15px;
      top: 50%;
      transform: translate(0, -50%);
      height: 48px;
      z-index: 10;
      border: dashed 2px $color-black;
    }
    &::after {
      content: '';
      position: absolute;
      left: -18px;
      top: 50%;
      transform: translate(0, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      z-index: 11;
      background-color: $color-mid-gray;
      border: 1px solid $color-black;
    }
  }
}

.month-container {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 4px;
}

.month-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 10px;
  padding: 0px 4px;

  &.active {
    background-color: #0050fc40;
  }

  &:hover {
    &.active {
      background-color: #0050fc40;
    }
    background-color: #e0e0e0;
    cursor: pointer;
  }
  &:not(:nth-child(3n)) {
    margin-right: 10px;
  }

  &:not(:nth-last-child(-n + 3)) {
    margin-bottom: 10px;
  }
}
.compenstaion-pie-box {
  width: 300px;
}
.go-back-icon {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  background-color: rgba(129, 129, 165, 0.1);
  color: #8181a5;
  text-align: center;
  line-height: 35px;
  margin: 0 4px;
  font-size: 16px;
  cursor: pointer;
}

.outer-circle-table {
  background: #385a94;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  position: relative;
  .inner-circle {
    position: absolute;
    background: white;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.height259px {
  height: 259px;
}

.compenstaion-bar-box {
  padding: 10px 15px 0px 15px;
}

.abs-form-element-icons {
  position: absolute !important;
  right: 6px;
  bottom: 10px;
}

.simple-box {
  padding: 10px 15px;
  border: 1px solid $color-light-gray;
  border-radius: 12px;
  &.selected {
    background-color: $color-dark-black;
    div {
      color: $color-white;
    }
  }
}

.tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  .tag {
    background-color: $color-s;
    border-radius: 6px;
    padding: 16px 14px;
    font-size: 14px;
    color: $color-white;
  }
}
.yearPickerContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 224px;
  .yearBox {
    cursor: pointer;
    width: 56px;
    padding: 4px 12px;
    margin-top: 4px;
    &.selected {
      color: black;
      background-color: #195ef62f;
      &.firstSelected {
        color: white;
        border-radius: 10px 0px 0px 10px;
        background-color: #195ef6;
      }
      &.lastSelected {
        color: white;
        border-radius: 0px 10px 10px 0px;
        background-color: #195ef6;
      }
      &.singleSelected {
        color: white;
        border-radius: 10px;
        background-color: #195ef6;
      }
    }
  }
}

.retrive-routes-route-card {
  border-radius: 6px;
  background-color: $color-base-gray;
  margin: 2px;
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

#icon-text-box-map-marker {
  background-color: white;
  border-radius: 5px;
  font-weight: bold;
}

.note-box-location-sugg {
  font-family: 'Roboto', sans-serif;
  padding: 6px 8px;
  outline: none;
  background-color: #f6f6f6;
  height: 69px;
  width: 276px;
  border-radius: 6px;
  border: 1px solid lightgray;
  resize: none;
}
.order-item-infobubble-card {
  position: relative;
  font-size: 11px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(#57697f, 0.52);
  border: 1px #cdddfe solid;
  margin: 14px 12px;
  .action-btns-container {
    display: grid;
    top: calc(50% - 33px);
    right: -12px;
    position: absolute;
  }
  .row-indicator {
    font-size: 12px;
    color: white;
    font-weight: bold;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 12px;
    padding: 2px;
    left: -8px;
    top: -8px;
    background-color: #195ef6;
  }
  .status-change-btn {
    font-size: 16px;
    color: white;
    font-weight: bold;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    padding: 2px;
    right: -8px;
    top: -8px;
    background-color: #195ef6;
    cursor: pointer;
    box-shadow: 0 10px 12px rgba(#57697f, 0.32);
  }
  .general-informations {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .general-informations-item {
      width: 50%;
      .general-informations-item-title {
        font-weight: 500;
        color: #8181a5;
      }
      .general-informations-item-value {
        margin-top: -3px;
      }
    }
  }
  .size-informations {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .size-informations-item {
      width: 32%;
      display: flex;
      .size-informations-item-title {
        font-weight: 500;
        margin-right: 3px;
        color: #8181a5;
      }
      .size-informations-item-value {
        color: black;
      }
    }
  }
}
.retrive-routes-search {
  margin-left: 6px;
  padding: 6px;
  border: 1px solid lightgray;
  border-radius: 4px;
  .retrive-routes-search-clear {
    cursor: pointer;
  }
  i {
    color: $color-mid-gray;
  }
  input {
    border: none;
    &:focus {
      outline: none;
    }
  }
}
.horizontal-line-container {
  display: flex;
  align-items: center;
  @include transition;
  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #fff;
    margin: 0 10px;
  }
  &.textless {
    height: 18px;
    i,
    span {
      display: none;
    }
    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid #fff;
      margin: 0;
    }
  }
}

.row-number {
  font-size: 12px;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  padding: 2px;
  background-color: #195ef6;
}

.progress-payment-single-report-table-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
}

.set-order-priority-date-button {
  padding: 4px 14px;
  position: absolute;
  left: 8px;
  top: 14px;
  background-color: #f4f4ff;
  border: 1px solid #b6b6d2;
  color: #0f182a;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    background-color: #fefde1;
    border: 1px solid #e7e48a;
  }
}
.set-order-priority-date-list {
  overflow-y: scroll;
  .set-order-priority-date-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #b6b6d2;
    border-radius: 10px;
    padding: 7px 12px;
    margin-bottom: 6px;
    .set-order-priority-date-list-item-checkbox-date {
      display: flex;
      align-items: center;
    }
    .set-order-priority-date-list-item-order-count {
      color: white;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #ff5c5c;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}

.optiyol-loader-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff794c !important;
  color: white !important;
  &:hover {
    background-color: #eb6e44 !important;
  }
}

.view-button-optiyol-loader {
  background-color: #dfe6fd;
  color: #041e64;
  padding: 2px 14px;
  border-radius: 6px;
  cursor: pointer;
  &.NOT_CREATED {
    cursor: not-allowed;
    background-color: #c3cade;
  }
  &:hover {
    background-color: #c3cade;
  }
}

.pdf-button-optiyol-loader {
  font-size: 20px;
  &.CREATED {
    cursor: pointer;
    color: #f04444;
  }
  &.NOT_CREATED {
    cursor: not-allowed;
    color: #b2b2b2;
  }
}

.load-planner-parameter-button {
  background-color: #f1f1f7;
  color: #000e32;
}

.view-load-plan-execptions-button {
  background-color: #ff4649;
  color: white;
  padding: 2px 8px;
  border-radius: 14px;
  font-size: 12px;
  cursor: pointer;
}

.view-load-plan-util-box {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: rgb(200, 223, 255);
  background: linear-gradient(180deg, rgba(200, 223, 255, 1) 0%, rgba(148, 193, 255, 1) 35%, rgba(123, 171, 237, 1) 100%);
  border-radius: 9px;
  &.overFlow-red {
    background: linear-gradient(180deg, rgba(255, 140, 142, 1) 0%, rgba(255, 81, 84, 1) 50%, rgba(255, 20, 20, 1) 100%);
  }
}

.circle-count {
  width: 20px;
  height: 20px;
  background-color: #8181a5;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
}

.temperature-chart {
  width: 90%;
  position: absolute;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 20px;
  left: 20px;
  z-index: 10;
  border-radius: 10px;
}
.all-violation-frame {
  display: flex;
  align-items: center;
  .violation-frame {
    &:not(:first-child) {
      margin-left: 12px;
    }
    margin-top: 10px;
    box-shadow: 0 3px 6px rgba(87, 105, 27, 0.16);
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 10px 15px;
  }

  .element-of-list-exceptions {
    margin-top: 4px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 8px;
  }
}
