
a {
	color: $color-s;
	text-decoration: none;


	&:hover {
		color: $color-s;
	}
	&:focus{
		outline: 0;
	}

	&.link-p{
		color: $color-p;

		&:hover{
			color:$color-s;
		}
	}

	&.link-s{
		color: $color-s;

		&:hover{
			color:$color-p;
		}
	}

	&.link-black{
		color: $color-black;

		&:hover{
			color: $color-p;
		}
	}

	&.link-mid-gray{
		color: $color-mid-gray;

		&:hover{
			color: $color-p;
		}
	}

	&.underlined{
		border-bottom: thin solid #fcd3d5;

		@include transition;
		
		&:hover{
			color: $color-p;
		}
	}
}