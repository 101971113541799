.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  height: 70px;
  font-size: 16px;
  font-weight: 600;
}

.dashboard {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    &-box {
      width: 94%;
      margin: 10px 0;
    }
    @include breakpoint(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
  }
  .grid .visualization-box {
    flex: 1 0 31%;
    @include breakpoint(tablet) {
      flex-basis: 94%;
    }
  }
  .content {
    padding: 10px 30px;
    .simple-box {
      width: 31%;
      margin: 10px;
      padding: 10px 15px;
      border: 1px solid $color-light-gray;
      border-radius: 12px;
    }
  }
}
.pick-option {
  padding: 10px 20px;
  border: 1px solid $color-light-gray;
  border-radius: 10px;
  margin: 0 20px;
  &.active {
    border-color: $color-p;
    color: $color-p;
  }
}
.visualization-box {
  width: 31%;
  margin: 10px;
  padding: 10px 15px;
  border: 1px solid $color-light-gray;
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  .item-list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
    }
    .item-point {
      border-radius: 50%;
      box-shadow: 0px 0px 0px 10px white;
      width: 3px;
      height: 3px;
    }
    .outer-circle {
      background: #385a94;
      border-radius: 50%;
      height: 11px;
      width: 11px;
      position: relative;
      .inner-circle {
        position: absolute;
        background: white;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .custom-tooltip {
    background-color: #eeeeeecc;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    &.block {
      display: block;
    }
  }

  .capacity-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    .capacity-tab {
      color: $color-mid-gray;
      padding: 6px 12px;
      margin-right: 7px;
      border-radius: 10px;
      border-bottom: 1px solid $color-light-gray;
      cursor: pointer;
      &.active {
        border: 1px solid $color-light-gray;
        color: #000;
        font-weight: 500;
      }
    }
  }
}

.company-group-box {
  padding: 10px 30px;
  .company-group {
    margin: 10px 0;
    display: flex;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    max-height: 200px;
    overflow: scroll;
    .group-item {
      cursor: pointer;
      padding: 5px 10px;
      margin: 3px;
      border-radius: 6px;
      border: 1px solid $color-light-gray;
      background-color: $color-light-gray;

      &.active {
        background-color: $color-p-mid;
        color: $color-white;
      }
    }
  }
}
