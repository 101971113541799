.chat-container {
  flex-direction: column;
  .chat-header {
    height: 65px;
    background-color: #283547;
    color: white;
    .chat-header-driver-name {
      padding: 0px 0px 0px 10px;
      font-weight: bold;
    }
    .chat-header-plate-number {
      padding: 0px 0px 0px 10px;
      font-size: 14px;
      font-weight: 300;
    }
    .chat-driver-badge {
      margin: 10px 0px 10px 10px;
      width: 40px;
      height: 40px;
      border-radius: 6px;
      background-color: lightgray;
    }
  }
  .chat-flow-container {
    flex-direction: column-reverse;
    overflow: scroll;
    .chat-flow-message-card {
      &.driver {
        justify-content: start;
      }
      &.master {
        justify-content: end;
      }
      .chat-flow-message-inner {
        margin-top: 6px;
        max-width: 338px;
        min-width: 135px;
        padding: 8px;
        position: relative;
        &.driver {
          border-radius: 15px 15px 15px 0px;
          background-color: #f6f6f6;
          color: black;
        }
        &.master {
          border-radius: 15px 15px 0px 15px;
          background-color: #185cf7;
          color: white;
        }
        .chat-flow-message-inner-text {
          margin-right: 45px;
          float: left;
        }
        .chat-flow-message-inner-time {
          justify-content: end;
          margin-top: -20px;
          float: right;
          padding-left: 4px;
          position: absolute;
          bottom: 4px;
          right: 10px;
        }
      }
    }
    .chat-flow-date-change {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .chat-text-container {
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #f5f5fa;
    .chat-text-input__suggestions {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      position: unset !important;
    }
    .chat-text-input__input {
      border-width: 0px;
    }
    .chat-text-container-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    textarea:focus,
    input:focus {
      outline: none;
    }
    .chat-text-input {
      background-color: transparent;
      border: none;
      width: 100%;
    }
    .chat-send-btn {
      cursor: pointer;
      padding: 6px 10px;
      background-color: #185cf7;
      border-radius: 8px;
      color: white;
      &:hover {
        background-color: #1753dd;
      }
    }
  }
}
.notification-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid white;
  background: #ff808b;
  &-big {
    height: 14px;
    width: 14px;
    position: absolute;
    border-radius: 50%;
    border: 3px solid white;
    background: #ff808b;
  }
}
