.tracking {
  &-list-item {
    padding: 20px 20px 20px 18px;
    outline: 1px solid #f0f0f3;
    cursor: pointer;
    .row {
      display: flex;
      .badge {
        width: 36px;
        height: 36px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
      .icons {
        margin-left: auto;
        .fas {
          background: none;
          height: unset;
          width: unset;
        }
      }
      .dashed-line-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 10px;
        .circle {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          background-color: white;
          border: 1px solid $color-dark-gray;
          &-filled {
            background-color: $color-dark-gray;
          }
        }
        .dashed-line {
          height: 11px;
          width: 1px;
          border-left: 1px dashed $color-dark-gray;
        }
      }
      .order-timeline {
        width: 100%;
        height: 4px;
        border-radius: 5px;
        display: flex;
        position: relative;
        .timeline-circle {
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          background-color: #fff;
          border: 1px solid #334155;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          @include transition;
        }
        .timeline-piece {
          height: 100%;
          &.green {
            background-color: #7ce7ac;
          }
          &.red {
            background-color: #e02020;
          }
          &.white {
            background-color: #f6f6f6;
          }
          &.yellow {
            background-color: #f7b500;
          }
          &.orange {
            background-color: #6d5000d8;
          }
        }
      }
    }
  }
  &-control-settings {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    .setting-icon {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #fff;
      border-radius: 4px;
      font-size: 20px;
    }
    .settings-box {
      opacity: 0;
      visibility: hidden;
      border-radius: 4px;
      position: absolute;
      right: 0;
      margin-top: 10px;
      width: 300px;
      padding: 9px 27px;
      background-color: #fff;
      @include transition;
      .row {
        display: flex;
        justify-content: space-between;
        margin: 13px 0;
        color: #1c1d21;
      }
      &.open {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.status-circle {
  width: 10px;
  display: inline-block;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  &.green {
    background-color: #6dd400;
  }
  &.red {
    background-color: #e02020;
  }
  &.yellow {
    background-color: #f7b500;
  }
  &.blue {
    background-color: $color-p;
  }
}
.timing-circle {
  width: 10px;
  display: inline-block;
  height: 10px;
  border-radius: 20%;
  margin-right: 5px;
  &.green {
    background-color: #6dd400;
  }
  &.red {
    background-color: #e02020;
  }
  &.yellow {
    background-color: #f7b500;
  }
  &.blue {
    background-color: $color-p;
  }
}
.vertical-splitter {
  width: 1px;
  height: 20px;
  background-color: $color-mid-gray;
}

.reoptimize-progress {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 3;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
  width: 350px;
  .box {
    padding: 15px 30px;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .progress-bar {
    height: 6px;
    width: 100%;
    .bar {
      height: 100%;
      background-color: $color-orange;
      @include transition;
    }
  }
}
.tracking-route-animation-box {
  .header {
    background-color: #283547;
    color: #fff;
    padding: 0px 20px;
    height: 84px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    i {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .order-info {
    top: 10px;
    z-index: 4;
    background-color: #fff;
    padding: 5px 20px;
    border-radius: 7px;
    color: #475569;
  }
  .footer {
    background-color: #fff;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .previous {
      position: absolute;
      cursor: pointer;
      left: 43%;
      top: 0px;
    }
    .next {
      position: absolute;
      cursor: pointer;
      right: 43%;
      top: 0px;
    }

    .play-controls {
      svg {
        .play-path {
          transform: scale(0.7) translate(5px, 5px);
        }
        .pause-path {
          transform: scale(0.3) translate(28px, 28px);
        }
        &:hover {
          circle {
            fill: #0947d7;
          }
        }
      }
      display: flex;
      align-items: flex-end;
      margin: 0;
      position: absolute;
      top: -36px;
      cursor: pointer;
      z-index: 3;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, 0);
    }
    .speed {
      top: -22.5px;
      left: 15%;
      padding: 10px 20px;
      border-radius: 10px;
      align-items: center;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 9px;
      i {
        background-color: $color-p;
        color: #fff;
        font-size: 15px;
        border-radius: 50%;
        padding: 5px;
      }
    }
    .line {
      width: 70%;
      background-color: #fff;
      height: 8px;
      bottom: 40px;
      border-radius: 4px;
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 9px;
      .blue-line {
        background-color: #195ef6;
        height: 8px;
        border-radius: 4px;
        transition: width 100ms;
        width: 0px;
      }
      .current {
        background-color: #195ef6;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        @include transition;
        left: 0px;
      }
    }
  }
}

svg.pulse-svg {
  overflow: visible;
  margin: -18px -14px !important;

  .first-circle {
    transform: scale(0.5);
    transform-origin: center center;
    animation: pulse-me 3s linear infinite;
  }
  .second-circle {
    @extend .first-circle;
    animation-delay: 1s;
  }
  .third-circle {
    @extend .first-circle;
    animation-delay: 2s;
  }
}

@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0.2;
  }
  50% {
    opacity: 0.3;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}

.delayed-row {
  background-color: #fefcf7;
  border-color: #f5d680;
  div {
    background-color: transparent;
  }
}

.badge-chat-list {
  width: 28px;
  height: 28px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.badge-status-circle {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid $color-white;
  top: -5px;
  right: -5px;
  &.green {
    background-color: #6dd400;
  }
  &.red {
    background-color: #e02020;
  }
  &.orange {
    background-color: #f59e26;
  }
  &.black {
    background-color: #000;
  }
  &.blue {
    background-color: $color-p;
  }
}
.message-count {
  position: absolute;
  top: -2px;
  left: 11px;
  color: $color-white;
  font-size: 10px;
  background-color: $color-p;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
