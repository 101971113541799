.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
  &-text {
    width: 100%;
    height: 0.5rem;
    border-radius: 0.45rem;
    &.short-80 {
      width: 80%;
    }
    &.short-50 {
      width: 50%;
    }
    &.short-30 {
      width: 30%;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 90%);
  }
}
