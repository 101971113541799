.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1002;
  padding: 1rem 0;
  box-sizing: border-box;
  background-color: rgba(#101725, 0.5);
  text-align: center;
  &.light {
    background-color: rgba(#101725, 0.5);
  }
}

.blocker:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.marker-info-modal {
  position: absolute;
  margin: 0;
  top: 50%;
  transform: translate(0, -50%);
  right: 5px;
  z-index: 3;
  box-sizing: border-box;
  width: 400px;
  height: 99%;
  background: #fbfbfd;
  padding: 15px 18px;
  border-radius: 16px;
  text-align: left;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
  .marker-info-content {
    height: 100%;
    position: relative;
  }
  .info-group {
    margin-top: 10px;
    .info-box {
      display: flex;
      flex-wrap: wrap;
      .column {
        flex: 1 1 48%;
      }
      .column-two {
        flex: 1 1 100%;
      }
    }
  }
  .order-list-panel {
    position: absolute;
    top: 0;
    width: 100%;
    background: #fbfbfd;
    @include transition;
    z-index: 2;
    .order-item {
      border: 1px solid $color-mid-gray;
      border-radius: 16px;
      padding: 5px 10px;
      margin: 5px 0;
      cursor: pointer;
      &:hover {
        background-color: $color-light-gray;
      }
    }
  }
  .marker-info-footer {
    position: absolute;
    bottom: 0;
    border-top: 1px solid black;
    width: 100%;
    padding: 8px 0;
  }
}

.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 90%;
  box-sizing: border-box;
  width: 540px;
  background: #fff;
  padding: 20px 38px;
  border-radius: 16px;
  text-align: left;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
  &.transition {
    @include transition;
  }
  &.mini {
    padding: 20px;
    width: 240px;
    position: absolute;
    right: 20px;
    top: calc(50% - 170px);
    box-shadow: none;
  }

  &.column {
    flex-flow: column wrap;
  }

  &-400 {
    max-width: 400px;
  }

  &-1040 {
    width: 1040px;
  }
}

.modal div.close-modal,
.marker-info-modal div.close-modal {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
  cursor: pointer;

  &:after {
    content: '×';
    position: absolute;
    font-weight: bold;
    top: 8px;
    right: 8px;
    text-indent: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: $color-black;
  }
}

.modal div.close-modal-white {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
  cursor: pointer;

  &:after {
    content: '×';
    position: absolute;
    color: white;
    top: 12px;
    right: 12px;
    text-indent: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
}
.modal-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modal-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modal-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modal-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.modal-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.5);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.optiyol-modal-body {
  color: #021e64;
  width: 100%;
  .fas {
    background: none;
    position: initial;
  }
  .btn.color-white {
    background: transparent;
    color: #475569;
  }
  .search-bar {
    display: flex;
    padding: 6px 10px;
    align-items: center;
    font-size: 12px;
    border: 1px solid #e2e8f0;
    margin-top: 10px;
    input {
      flex: 1;
      background-color: transparent;
      border: 0;
      color: #8181a5;
      &:focus {
        border: 0;
        outline: 0;
      }
    }
    .fas {
      background: none;
    }
  }
}

.optiyol-modal-change-order-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  &-right {
    iframe {
      border-radius: 12px;
    }
  }
}

.optiyol-upload-excel-file-area {
  position: relative;
  padding: 32px 48px;
  text-align: center;
  cursor: pointer;
  background: #f1f5f9;
  border: 2px dashed #cbd5e1;
  border-radius: 8px;

  i {
    font-size: 90px;
    color: #94a3b8;
  }

  input[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
}

.modal-select {
  background-color: transparent;
  border: 1px solid #f0f0f3;
  border-radius: 10px;
  padding: 20px 10px 5px 10px;
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  @include transition;
  &-active {
    background-color: rgba(138, 241, 185, 0.1);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background-color: #8af1b9;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: -11px;
      right: -7px;
    }
    &::after {
      content: '\02143';
      position: absolute;
      transform: rotate(40deg) scale(0.8);
      color: #fff;
      top: -14px;
      right: -1px;
    }
  }
  .fas {
    font-size: 56px;
  }
}

.status-select {
  background-color: transparent;
  border: 1px solid #f0f0f3;
  border-radius: 10px;
  padding: 16px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  @include transition;
  &-active {
    background-color: rgba(138, 241, 185, 0.1);
    position: relative;
    border: 1px solid #6dd400;
    &::before {
      content: '';
      position: absolute;
      background-color: #8af1b9;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: 20px;
      right: 10px;
    }
    &::after {
      content: '\02143';
      position: absolute;
      transform: rotate(40deg) scale(0.8);
      color: #fff;
      top: 18px;
      right: 16.5px;
    }
  }
  &.btn-disabled {
    background-color: #d9d9d9;
  }
  .fas {
    font-size: 20px;
  }
  .text-green {
    color: #6dd400;
  }
}

.master-data-modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  width: 90%;
  height: 90%;
  box-sizing: border-box;
  background: #fff;
  padding: 5px;
  border-radius: 16px;
  text-align: left;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
  .inner {
    margin: 0 !important;
  }
  .master-data-layout {
    width: 100%;
  }
  .search-bar {
    margin: 0;
    border: 0;
  }
  aside {
    display: none;
  }
  .exit-modal {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 17px;
    cursor: pointer;
    color: $color-black;
  }
}

.complete-form {
  background-color: rgba(138, 241, 185, 0.03);
  padding: 10px 20px;
  margin-top: -10px;
  border: 1px solid #6dd400;
  border-radius: 10px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: -1;
  input {
    background-color: rgba(138, 241, 185, 0.01);
    height: 32px;
    font-weight: bold;
  }
  .react-select {
    &__control {
      background-color: unset;
    }
    &__single-value {
      font-weight: bold;
    }
  }
}

#optiyol-modal-track-filter {
  .display-date {
    border-bottom: 1px solid #021e64;
    border-radius: 3px;
    position: relative;
    padding: 10px;
  }
}

.mail-modal-conatiner-extras {
  padding: 10px 20px;
  width: 500px;
  flex-direction: column;
  .mail-modal-input-container {
    display: flex;
    flex-direction: column;
    .mail-modal-input-title {
      color: #8181a5;
    }
    textarea:focus,
    input:focus {
      outline: none;
    }
    .mail-modal-input-input {
      padding: 10px;
      border: none;
      border-bottom: 1px solid lightgray;
    }
    textarea {
      border-radius: 8px;
      margin-top: 16px;
      width: 100%;
      height: 100px;
      border-color: lightgray;
      resize: none;
      padding: 5px;
    }
  }
  .mail-modal-btn {
    cursor: pointer;
    font-weight: 600;
    padding: 12px 20px 12px 20px;
    font-size: 14px;
    &.color-n {
      background-color: #f6f6f6;
      color: #8181a5;
    }
  }
}
