@mixin breakpoint($point) {
  @if $point==larger-web {
    @media (max-width: 1920px) {
      @content;
    }
  }

  @if $point==web {
    @media (max-width: 1600px) {
      @content;
    }
  }

  @if $point==tablet {
    @media (max-width: 1144px) {
      @content;
    }
  }

  @if $point==mobile {
    @media (max-width: 570px) {
      @content;
    }
  }
}
